import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import {
    TextField,
    Grid,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Button,
    InputAdornment,
    IconButton,
    Typography,
    Box,
    Stack,
    Modal,
    Divider,
    SvgIcon,
    Autocomplete,
    Switch,
    CircularProgress,
    TextareaAutosize,
    Tooltip,
    Collapse,
    Paper,
    FormControlLabel,
    Checkbox,
    LinearProgress
} from "@mui/material";
import Header from "../../../components/headers/Header";
import AddIcon from '@mui/icons-material/Add';
import '../generated_blogs.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactComponent as CardIcon } from '../../../assets/card_ico.svg';
import { ReactComponent as TwitterIcon } from '../../../assets/traffic_monster/icon_twitter.svg';
// import { ReactComponent as XIcon } from '../../../assets/traffic_monster/x_ico.svg';
import { ReactComponent as PinterestIcon } from '../../../assets/traffic_monster/pinterest_ico.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/traffic_monster/fb_ico.svg';
import { ReactComponent as LinkedinIcon } from '../../../assets/traffic_monster/linkedIn_ico.svg';
import { CheckBox, Height, Widgets } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as VerifiedIcon } from '../../../assets/verified_ico.svg';
import { isBrowser, isMobile } from "react-device-detect";
import { useCreateCampaignMutation } from "../../../redux/api/promptApi";
import { toast } from "react-toastify";
import { useLazyCreateCheckoutSessionQuery } from "../../../redux/api/subscriptionApi";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { usePlanDetailsMutation } from "../../../redux/api/productApi";
import { useGetUserDetailsMutation } from "../../../redux/api/authApi";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetpagefrocampaignMutation, useGetCnameEntriesMutation, useUpdateAutoPublishFlagMutation, useGetverifyscriptMutation, useCheckverifiedMutation } from "../../../redux/api/promptApi";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { id } from "date-fns/locale";
import CloseIcon from '@mui/icons-material/Close';
import { IPlanDetail } from "../../../redux/api/types";
import { useLazyGetProductQuery } from '../../../redux/api/productApi';
import { useAppSelector } from '../../../redux/store';
import { ReactComponent as Twitterico } from '../../../assets/traffic_monster/twitter__ico.svg';
import { ReactComponent as Pinterestico } from '../../../assets/traffic_monster/pinterest__ico.svg';
import { ReactComponent as Fbico } from '../../../assets/traffic_monster/fb__ico.svg';
import { ReactComponent as Linkedinico } from '../../../assets/traffic_monster/linkedin__ico.svg';
import axios from 'axios';
import { useCampaignListMutation } from "../../../redux/api/promptApi";
import InfoIcon from '@mui/icons-material/Info';
import YouTubeIcon from '@mui/icons-material/YouTube';
import youtubeIco1 from '../../../assets/traffic_monster/youtubeIco_1.png'
import { upload } from "@testing-library/user-event/dist/upload";
import ProgressBar from "@ramonak/react-progress-bar";


interface ErrorResponse {
    error: {
        status: number;
        data: {
            detail: string;
        };
    };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: '#D0D5DD',
        color: 'lightblack',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'white', // Set background color for odd rows to white
        color: 'black', // Set text color for odd rows to black
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#F2F6FA', // Set background color for even rows to gray
        color: 'darkslategray',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const HeaderCell = styled('div')(({ theme }) => ({
    color: '#626F86',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '12px',
    textAlign: 'center',
    '&:nth-child(odd)': {
        backgroundColor: '#EAECF0',
    },
    '&:nth-child(even)': {
        backgroundColor: '#F5F5F5',
    }
}));

const DataCell = styled('div')(({ theme }) => ({
    color: '#344054',
    fontSize: '14px',
    padding: '12px',
    textAlign: 'center',
    '&:nth-child(odd)': {
        backgroundColor: '#EAECF0',
    },
    '&:nth-child(even)': {
        backgroundColor: '#F5F5F5',
    }
}));



const New_Campaign = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { FromEdit } = location.state || {};
    const { campIdFrEdit } = location.state || {};
    const { subscriptionFromCampList } = location.state || {};
    const { subscriptionTH } = location.state || {};
    const { mode } = location.state || {};

    console.log(subscriptionTH);
    console.log(mode);
    const [sourceUrl, setSourceUrl] = useState<string>('');
    const [sourceText, setSourceText] = useState<string>('');
    const [videoTag, setVideoTag] = useState<string>('');
    const [twitterUrl, settwitterUrl] = useState<string>('');
    const [fbUrl, setfbUrl] = useState<string>('');
    const [pintrestUrl, setPintrestUrl] = useState<string>('');
    const [linkedinUrl, setLinkedinUrl] = useState<string>('');
    const [ctaLabel, setCtaLabel] = useState<string>('');
    const [ctaLink, setCtaLink] = useState<string>('');

    const [campaignName, setCampaignName] = useState<string>('');
    const [urlPrefix, setUrlPrefix] = useState<string>('https://');
    const [expanded, setExpanded] = useState(true);
    const [expanded1, setExpanded1] = useState(true);
    const [expanded2, setExpanded2] = useState(true);
    const [expanded3, setExpanded3] = useState(false);
    const [expanded4, setExpanded4] = useState(false);
    const [expanded5, setExpanded5] = useState(false);
    const [expanded6, setExpanded6] = useState(false);
    const [expanded7, setExpanded7] = useState(false);
    const [expanded8, setExpanded8] = useState(false);
    const [expanded9, setExpanded9] = useState(false);
    const [expanded10, setExpanded10] = useState(false);
    const [expanded11, setExpanded11] = useState(false);
    const [expanded12, setExpanded12] = useState(false);
    const [expanded13, setExpanded13] = useState(false);
    const [planDetails, setPlanDetails] = useState<any>();
    const [defaultPlanDetails, setDefaultPlanDetails] = useState<any>();
    const [campaignId, setCampaignId] = useState<any>();
    const [userDetails, setUserDetails] = useState<any>();
    const [openpaymentInstructModal, setOpenPaymentInstructModal] = useState<boolean>(false);
    const [paymentOption, setPaymentOption] = useState<any>();
    const [paymentOptYearlyCal, setPaymentOptYearlyCal] = useState<any>();
    const paymentSectionRef = useRef<HTMLDivElement>(null);
    const getcnameEntriesRef = useRef<HTMLDivElement>(null);
    const getVerifyScript = useRef<HTMLDivElement>(null);
    const [autoPublish, setAutopublish] = useState<any>(true);
    const [limitPublish, setLimitPublish] = useState<any>(true);
    const [socialMediaUrlPublish, setSocialMediaUrlPublish] = useState<any>();
    const [addtags, setAddTags] = useState<any>(true);
    const [capturleads, setCapturLeads] = useState<any>(true);
    const [newsletter, setNewsLetter] = useState<any>(true);
    const [twitternbutton, setTwitternButton] = useState<any>(true);
    const [fbbutton, setFbButton] = useState<any>(true);
    const [pintrestbutton, setPintrestButton] = useState<any>(true);
    const [linkedinbutton, setLinkedinButton] = useState<any>(true);
    const [video, setVideo] = useState<any>(true);
    const [useOwnDomain, setUseOwnDomain] = useState<any>(false);
    const [isloadingGetPage, setIsLoadingGetPage] = useState<boolean>(false);
    const [openGetPagesuccessModal, setOpenGetPageSuccessModal] = useState<boolean>(false);
    const [confirmCancelSubscription, setConfirmCancelSubscription] = useState<boolean>(false);
    const [cnameRecords, setCnameRecords] = useState<any>();
    const [cnameDomainName, setCnameDomainName] = useState<any>();
    const [isLoadingCnameRec, setIsLoadingCnameRec] = useState<boolean>(false);
    const getpageRunref = useRef(false);
    const [script, setScript] = useState<any>();
    const [verifyCheck, setVerifyCheck] = useState<any>();
    const [period, getPeriodState] = useState("monthly");
    const [createCampaignMutation] = useCreateCampaignMutation();
    const user_email = localStorage.getItem('user_email') || '';
    const [isUpdateLoader, setIsUpdateLoader] = useState<boolean>(false);
    const [selectedSubscriptionLevel, setSelectedSubscriptionLevel] = useState<string>('');

    const [getProduct, getState] = useLazyGetProductQuery();
    const [plans, setPlans] = useState<IPlanDetail[]>([
        {
            user: 1,
            plan_name: "",
            total_wishes: 0,
            price: "",
            period: "",
            product_id: "",
            price_id: "",
            subscription_level: "",
            feature: [],
            contracts: 0,
            type: "",
            button_text: "",
        },
    ]);
    const [productModule, setProductModule] = useState("");
    const genieSelector = useAppSelector((state) => state.genieState);

    const [createCheckoutSession, checkoutState] = useLazyCreateCheckoutSessionQuery();
    const [getUserDetailsMutation] = useGetUserDetailsMutation();

    // console.log('is this from edit=>', FromEdit);
    const [getuserPlanDetails] = usePlanDetailsMutation();
    const [getPageforCampaign] = useGetpagefrocampaignMutation();
    const [getCnameEntriesMutation] = useGetCnameEntriesMutation();
    const [getverifyscript] = useGetverifyscriptMutation();
    const [getcheckverified] = useCheckverifiedMutation();
    const [updateAutoPublishCheckMutation] = useUpdateAutoPublishFlagMutation();


    const [userImage, setUserImage] = useState<File | null>(null);
    const [userImageUrl, setUserImageUrl] = useState('');
    const [socialUrl, setSocialUrl] = useState('');
    const [name, setName] = useState('');
    const [useAsDefault, setUseAsDefault] = useState(true);
    const [cancellingLoader, setCancellingLoader] = useState<boolean>(false);
    const [campaignListMutation] = useCampaignListMutation();
    const [campaignDatas, setCampaignDatas] = useState<any>();
    const [couponCode, setCouponCode] = useState<string>('');
    const [successCancelSubscription, setSuccessCancelSubscription] = useState(false);
    const [podcastoryoutubeurl, setPodcastOrYoutubeUrl] = useState('');
    const [selectedSection, setSelectedSection] = useState('websiteSection');
    const [uploadVideo, setUploadVideo] = useState('');
    const [isUploadLoading, setIsUploadLoading] = useState(false);
    const [uploadedvideopath, setUploadedvideopath] = useState('');
    const [getPageError, setGetPageError] = useState(false);
    const [loaderProgress, setLoaderProgress] = useState<any>(4);

    const [loadingText, setLoadingText] = useState("Processing your video...");
    const [intervaltime, setIntervalTime] = useState(2000);
    const handleCouponTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCouponCode(event.target.value);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleExpandClick1 = () => {
        setExpanded1(!expanded1);
    };
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    }
    const handleExpandClick3 = () => {
        /* if (selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
            || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5' || defaultPlanDetails?.mode == "Free Trial"
            || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
            || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') {
            setExpanded3(false);
            return false;
        }
        if (planDetails)
            if (planDetails?.subscription_level != "level_3" && planDetails?.subscription_level != "level_6") {
                setExpanded3(false);
                return false;
            } */
        setExpanded3(!expanded3);
    }

    const handleExpandClick4 = () => {
        setExpanded4(!expanded4)
    }

    const handleExpandClick5 = () => {
        setExpanded5(!expanded5);
    }

    const handleExpandClick6 = () => {
        /* if (selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
            || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5' || defaultPlanDetails?.mode == "Free Trial"
            || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
            || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') {
            setExpanded6(false);
            return false;
        }
        if (planDetails)
            if (planDetails?.subscription_level != "level_3" && planDetails?.subscription_level != "level_6") {
                setExpanded6(false);
                return false;
            } */
        setExpanded6(!expanded6);
    }

    const handleExpandClick7 = () => {

        /* if (selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
            || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5' || defaultPlanDetails?.mode == "Free Trial"
            || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
            || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') {

            setExpanded7(false);
            return false;
        }
        if (planDetails)

            if (planDetails?.subscription_level != "level_3" && planDetails?.subscription_level != "level_6") {
                setExpanded7(false);
                return false;
            } */


        setExpanded7(!expanded7);
    }

    const handleExpandClick8 = () => {
        /*  if (selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
             || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5' || defaultPlanDetails?.mode == "Free Trial"
             || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
             || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') {
             setExpanded8(false);
             return false;
         }
         if (planDetails)
             if (planDetails?.subscription_level != "level_3" && planDetails?.subscription_level != "level_6") {
                 setExpanded8(false);
                 return false;
             } */
        setExpanded8(!expanded8);
    }

    const handleExpandClick9 = () => {
        /* if (selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
            || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5' || defaultPlanDetails?.mode == "Free Trial"
            || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
            || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') {
            setExpanded9(false);
            return false;
        }
        if (planDetails)
            if (planDetails?.subscription_level != "level_3" && planDetails?.subscription_level != "level_6") {
                setExpanded9(false);
                return false;
            } */
        setExpanded9(!expanded9);
    }

    const handleExpandClick10 = () => {
        setExpanded10(!expanded10);
    }

    const handleExpandClick11 = () => {
        setExpanded11(!expanded11);
    }

    const handleExpandClick12 = () => {
        setExpanded12(!expanded12);
    }

    useEffect(() => {
        const campaignRes = async () => {
            try {
                const response = await campaignListMutation({ email: user_email });
                if ('data' in response) {
                    setCampaignDatas(response.data);
                    console.log('campaign list response=>', response.data)
                } else {
                    console.error('Error fetching campaign List:', response.error);
                }
            } catch (error) {
                console.log('Error fetching campaign List:', error);
            }
        }
        campaignRes();
    }, [])

    useEffect(() => {
        localStorage.removeItem('publishingCampId');
    }, []);

    useEffect(() => {

        const value = {
            _id: "",
            product_name: "CodeGenie",
            //product_module: genieSelector.module ? genieSelector.module : ""
            product_module: `${process.env.REACT_APP_MAIN_PRODUCT_NAME}`,
            userid: "",
            productid: ""
        };
        getProduct(value);
    }, [genieSelector, getProduct]);

    useEffect(() => {

        const { data } = getState;
        if (data?.plan_details) {
            console.log(data?.plan_details);
            setPlans([...data.plan_details]);
            console.log('plan price det=>', plans);
            setProductModule(data.product_module);
        }
        else
            setPlans([
                {
                    user: 1,
                    plan_name: "",
                    total_wishes: 0,
                    price: "",
                    period: "",
                    product_id: "",
                    price_id: "",
                    subscription_level: "",
                    feature: [],
                    contracts: 0,
                    type: "",
                    button_text: "",
                },
            ]);
    }, [getState]);

    const validateCampaignName = () => {
        /* if(campaignName === ''){
            toast.error('Please fill your Campaign Name!');
            return false;
        } */

        const isDuplicate = campaignDatas.some((campaign: any) => campaign.campaign_name === campaignName);
        if (isDuplicate) {
            toast.error('Duplicate campaign Name. Please choose a different name!');
            return false;
        }
        return true;
    }

    const youtubeMatch = videoTag?.match(
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );

    const handleCreateCampaignClickold = async () => {
        if (FromEdit === 'startEditing' || localStorage.getItem('ChangepayMode') === 'true') {
            if (video && !videoTag) {
                toast.error('Please enter YouTube video URL on video section');
            } else if (video && !youtubeMatch) {
                toast.error('Please enter only YouTube video URL');

            } else {
                autoPublishCheck(campIdFrEdit);
                handleUpdateSetting(campIdFrEdit);
                localStorage.removeItem('ChangepayMode');
                localStorage.removeItem('IdChangingMode');
            }
            // navigate('/codegenie/generated-blogs', { state: { FromUpdatedCampaign: campIdFrEdit } });
            // if(planDetails?.owndomain === '1'){

            // }
        }
        else {
            // debugger;
            // const isDuplicate = campaignDatas.some((campaign: any) => campaign.campaign_name === campaignName);
            if (campaignName === '' /* || sourceUrl === '' || !paymentOption || (!limitPublish && !autoPublish) */) {
                // toast.error(`Please fill all the * required fields!`);
                toast.error('Please fill your Campaign Name!');
            } /* else if (isDuplicate) {
                toast.error('Duplicate campaign Name. Please choose a different name!');
            } */ else if (
                (urlPrefix === 'text' && sourceText === '') ||
                (urlPrefix !== 'text' && sourceUrl === '')
            ) {
                toast.error('Please fill your Source Page URL/TEXT');
            }
            else if (!paymentOption) {
                toast.error('Please choose the payment option!');
                return;
            } /* else if (!autoPublish) {
                toast.error('Please fill the autopublish blog!');
            } */ else if (video && !videoTag) {
                toast.error('Please enter YouTube video URL on video section');
            } else if (video && !youtubeMatch) {
                toast.error('Please enter only YouTube video URL');

            }
            /* else if (!name) {
                toast.error('Please fill the required field on author section!');
            } */
            else {
                try {
                    var tmpurl = urlPrefix === 'https://' ? (document.getElementById('sourceurl') as HTMLInputElement)?.value : '';
                    if (tmpurl.indexOf("http") < 0) tmpurl = urlPrefix === 'https://' ? "https://" + tmpurl : '';

                    const finalurl = tmpurl;
                    const youtubeMatch = finalurl?.match(
                        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                    );

                    setIsLoadingGetPage(true);
                    const videoid = youtubeMatch ? youtubeMatch[1] : '';

                    const response = await createCampaignMutation({ campaign_name: campaignName, url: finalurl ? encodeURIComponent(finalurl) : '', email: user_email, sourcemode: urlPrefix === 'text' ? 'text' : 'url', videoid: videoid ? videoid : '', product_desc: sourceText ? encodeURIComponent(sourceText) : '' });
                    console.log("response==" + response);


                    if ('data' in response) {

                        console.log('created campaign result=>', response.data);//getting campaign id here
                        if (response.data.status === 'success') {
                            setCampaignId(response.data.campaign_id);
                            autoPublishCheck(response.data.campaign_id);
                            handleUpdateSetting(response.data.campaign_id);
                            handleVerifyCheckClick(response.data.campaign_id);
                            toast.success(response.data.msg);
                            setIsLoadingGetPage(false);
                            localStorage.setItem('fromNewcampaign', 'true');
                            handleCheckout(response.data.campaign_id);
                            // setOpenPaymentInstructModal(true);
                        } else if (response.data.status === 'failed') {
                            setIsLoadingGetPage(false);
                            toast.error(response.data.msg);
                        }

                    }
                    else {
                        const errorMessage = (response?.error as any)?.data?.detail as string | undefined;

                        toast.error(errorMessage);
                        setIsLoadingGetPage(false);
                        //window.location.reload();
                    }
                } catch (error) {

                    setIsLoadingGetPage(false);
                    console.log('Error creating campaign:', error);
                }
            }
        }
    }

    useEffect(() => {
        let intervalId: any;
        if (isloadingGetPage && loaderProgress < 100) {
            intervalId = setInterval(() => {
                setLoaderProgress((prevProgress: any) => {
                    const newProgress = prevProgress + 2;
                    return newProgress >= 98 ? 98 : newProgress;
                });
            }, intervaltime);
        }

        return () => clearInterval(intervalId);
    }, [isloadingGetPage, loaderProgress]);

    const handleCreateCampaignClick = async () => {
        if (FromEdit === 'startEditing' || localStorage.getItem('ChangepayMode') === 'true') {
            if (video && !videoTag) {
                toast.error('Please enter YouTube video URL on video section');
            } else if (video && !youtubeMatch) {
                toast.error('Please enter only YouTube video URL');

            } else {
                autoPublishCheck(campIdFrEdit);
                handleUpdateSetting(campIdFrEdit);
                localStorage.removeItem('ChangepayMode');
                localStorage.removeItem('IdChangingMode');
            }
            // navigate('/codegenie/generated-blogs', { state: { FromUpdatedCampaign: campIdFrEdit } });
            // if(planDetails?.owndomain === '1'){

            // }
        }
        else {
            // debugger;
            // const isDuplicate = campaignDatas.some((campaign: any) => campaign.campaign_name === campaignName);
            if (campaignName === '' /* || sourceUrl === '' || !paymentOption || (!limitPublish && !autoPublish) */) {
                // toast.error(`Please fill all the * required fields!`);
                toast.error('Please fill your Campaign Name!');
            } /* else if (isDuplicate) {
                toast.error('Duplicate campaign Name. Please choose a different name!');
            } */ else if (
                (urlPrefix === 'upload' && uploadVideo === '') ||
                (urlPrefix !== 'upload' && sourceUrl === '')
            ) {
                toast.error('Please fill your youtube video url/upload video file!');
            }
            else if (!paymentOption) {
                toast.error('Please choose the payment option!');
                return;
            } /* else if (!autoPublish) {
                toast.error('Please fill the autopublish blog!');
            } */ else if (video && !videoTag) {
                toast.error('Please enter YouTube video URL on video section');
            } else if (video && !youtubeMatch) {
                toast.error('Please enter only YouTube video URL');

            }
            /* else if (!name) {
                toast.error('Please fill the required field on author section!');
            } */
            else {
                try {
                    var tmpurl = urlPrefix === 'https://' ? (document.getElementById('sourceurl') as HTMLInputElement)?.value : '';
                    if (tmpurl.indexOf("http") < 0) tmpurl = urlPrefix === 'https://' ? "https://" + tmpurl : '';

                    const finalurl = tmpurl;
                    const youtubeMatch = finalurl?.match(
                        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|watch)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
                    );

                    setIsLoadingGetPage(true);
                    const videoid = youtubeMatch ? youtubeMatch[1] : '';

                    const formData = new FormData();
                    formData.append('campaign_name', campaignName);
                    formData.append('url', '');
                    formData.append('email', user_email);
                    formData.append('sourcemode', urlPrefix === 'upload' ? 'upload' : 'youtube');
                    formData.append('videoid', videoid);
                    formData.append('product_desc', '');
                    formData.append('media_file_path', uploadedvideopath);

                    const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
                    const response = await axios.post(
                        `${apiUrl}/api/prompts/create_campaign`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    console.log(response);
                    if (response.data.status === 'success') {
                        setLoaderProgress(100);
                        setCampaignId(response.data.campaign_id);
                        autoPublishCheck(response.data.campaign_id);
                        handleUpdateSetting(response.data.campaign_id);
                        handleVerifyCheckClick(response.data.campaign_id);
                        setTimeout(() => {
                            toast.success(response.data.msg);
                            setIsLoadingGetPage(false);
                            localStorage.setItem('fromNewcampaign', 'true');
                            handleCheckout(response.data.campaign_id);
                        }, 2000);
                        // setOpenPaymentInstructModal(true);
                    } else if (response.data.status === 'failed') {
                        setIsLoadingGetPage(false);
                        toast.error(response.data.msg);
                    }

                } catch (error) {

                    setIsLoadingGetPage(false);
                    console.log('Error creating campaign:', error);
                }
            }
        }
    }

    useEffect(() => {
        if (FromEdit === 'startEditing') {
            getVerifyingScript(campIdFrEdit);
            handleVerifyCheckClick(campIdFrEdit);
            cnameRec(campIdFrEdit);
        }
    }, []);

    const cnameRec = async (campIdFrEdit: string) => {
        try {
            const response = await getCnameEntriesMutation({ email: user_email, campaignid: campIdFrEdit || '' });
            if ('data' in response) {
                console.log('cnameentries=>', response.data.cnamerecords);
                setCnameDomainName(response.data);
                setCnameRecords(response.data.cnamerecords);
            }
        } catch (error) {
            console.log('Error fetching dns record:', error);
        }
    }

    const fetchPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email, campaignid: campIdFrEdit || localStorage.getItem('IdChangingMode') || '' });
            if ('data' in response) {

                setPlanDetails(response.data.data);
                console.log('plan details***=>', response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchDefaultPlanDatas = async () => {
        try {
            const response = await getuserPlanDetails({ email: user_email, campaignid: '' });
            if ('data' in response) {

                setDefaultPlanDetails(response.data.data);
                console.log('default plan details***=>', response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (FromEdit === 'startEditing' || localStorage.getItem('ChangepayMode') === 'true' || successCancelSubscription === true) {
            fetchPlanDatas();
        }
    }, [successCancelSubscription]);

    useEffect(() => {

        fetchDefaultPlanDatas();

    }, []);

    const autoPublishCheck = async (id: string) => {
        setIsUpdateLoader(true);
        try {
            const response = await updateAutoPublishCheckMutation({ campaign_id: id, autopublish: autoPublish ? 1 : 0, threeblogperday: limitPublish ? 1 : 0, addtags: addtags ? 1 : 0, capturleads: capturleads ? 1 : 0, twitterurl: planDetails?.twitterurl || twitterUrl || '', fburl: planDetails?.fburl || fbUrl || '', pintresturl: planDetails?.pintresturl || pintrestUrl || '', linkedinurl: planDetails?.linkedinurl || linkedinUrl || '', newsletter: newsletter ? 1 : 0, twitternbutton: twitternbutton ? 1 : 0, fbbutton: fbbutton ? 1 : 0, pintrestbutton: /* pintrestbutton ? 1 : */ 0, linkedinbutton: linkedinbutton ? 1 : 0, video: video ? 1 : 0, owndomain: useOwnDomain ? 1 : 0, cta_lable: ctaLabel ? encodeURIComponent(ctaLabel) : planDetails?.cta_lable ? encodeURIComponent(planDetails?.cta_lable) : 'Learn More', cta_link: ctaLink ? encodeURIComponent(ctaLink) : planDetails?.cta_link ? encodeURIComponent(planDetails?.cta_link) : sourceUrl, videotag: encodeURIComponent(videoTag), iframe: 1 });
            console.log(response);
            if (FromEdit === 'startEditing') {
                if ('data' in response) {
                    console.log(response.data.msg);

                    // toast.success(response.data.msg);
                    if (FromEdit === 'startEditing') {
                        toast.success('Campaign settings updated successfully!');
                    } else {
                        toast.success(response.data.msg);
                    }
                }
            }
        } catch (error) {
            console.log('Error:', error);
        } finally {
            setIsUpdateLoader(false);
        }
    }

    const fetchData = async () => {
        try {
            const response = await getUserDetailsMutation({ email: user_email });

            if ('data' in response) {
                // const datas = response.data.data;
                setUserDetails(response.data.data);
                console.log('userDet =>', userDetails);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleCheckout = async (campaignId: string) => {

        let referral_param = localStorage.getItem("referral_param");
        localStorage.removeItem("referral_param");
        /* let couponcode = localStorage.getItem("couponcode");
        localStorage.removeItem("couponcode"); */

        try {
            const response = await createCheckoutSession({ "emailid": user_email || "", "username": userDetails.name, "subscription_level": selectedSubscriptionLevel/* paymentOption === '$14/mo.' ? 'level_0' : paymentOption === '$49/mo.' ? 'level_1' : paymentOption === '$99/mo.' ? 'level_2' : paymentOption === '$149/mo.' ? 'level_3' : (paymentOption === '$12/mo.' && period === 'yearly') ? 'level_0y' : paymentOption === '$41/mo.' ? 'level_4' : paymentOption === '$83/mo.' ? 'level_5' : paymentOption === '$124/mo.' ? 'level_6' : '' */, "referral_param": referral_param || "", "couponcode": couponCode ?? "", "mode": '', campaignid: campaignId || '', "from_page": 'campaign' });
            debugger;
            if (response.data.message == "Checkout url created") {
                // Assuming response contains the checkout URL
                const checkoutUrl = response.data.checkout_url;

                // Redirect to the Stripe checkout page
                window.location.href = checkoutUrl;
            }
            else if (response.data.error) {
                const errorMessage = response.data.error;
                if (errorMessage.includes('This promotion code cannot be redeemed because the associated customer has prior transactions.')) {
                    toast.error('Coupon code already applied');
                } else if (errorMessage.includes('This coupon cannot be redeemed because it does not apply to anything in this order.')) {
                    toast.error('Invalid Coupon code');
                } else {
                    toast.error('Invalid Coupon code');
                }
            }
            /* else {
                console.log('checkout res=>', response);
                toast.error("Something went wrong, checkout url not created");
            } */

        }
        catch (e) {
            alert(e);
            console.log(e);
        }
    }

    const data = [
        { heading: 'QUANTITY', value: '1' },
        { heading: 'PLAN TYPE', value: period === 'monthly' ? 'Monthly' : 'Yearly' },
        { heading: 'PLAN NAME', value: planDetails ? (planDetails.subscription_level === 'level_0' || planDetails.subscription_level === 'level_0y' ? 'Starter' : planDetails.subscription_level === 'level_1' || planDetails.subscription_level === 'level_4' ? 'Basic' : planDetails.subscription_level === 'level_2' || planDetails.subscription_level === 'level_5' ? 'Pro' : planDetails.subscription_level === 'level_3' || planDetails.subscription_level === 'level_6' ? 'Podcast Hacker' : '') : selectedSubscriptionLevel ? (selectedSubscriptionLevel === 'level_0' || selectedSubscriptionLevel === 'level_0y' ? 'Starter' : selectedSubscriptionLevel === 'level_1' || selectedSubscriptionLevel === 'level_4' ? 'Basic' : selectedSubscriptionLevel === 'level_2' || selectedSubscriptionLevel === 'level_5' ? 'Pro' : selectedSubscriptionLevel === 'level_3' || selectedSubscriptionLevel === 'level_6' ? 'Podcast Hacker' : '') : '' },
        { heading: 'TOTAL', value: planDetails ? (planDetails?.subscription_level === 'level_0' ? '$14/mo.' : planDetails?.subscription_level === 'level_1' ? '$49/mo.' : planDetails?.subscription_level === 'level_2' ? '$99/mo.' : planDetails?.subscription_level === 'level_3' ? '$149/mo.' : planDetails?.subscription_level === 'level_0y' ? '$' + (12 * 12).toFixed(1) + '/yr.' : planDetails?.subscription_level === 'level_4' ? '$' + (41 * 12).toFixed(1) + '/yr.'/* '$82.5/mo.' */ : planDetails?.subscription_level === 'level_5' ? '$' + (83 * 12).toFixed(1) + '/yr.'/* '$165.8/mo.' */ : planDetails?.subscription_level === 'level_6' ? '$' + (124 * 12).toFixed(1) + '/yr.'/* '$249.16/mo.' */ : '') : /* paymentOption */paymentOptYearlyCal },
        // { heading: 'PAYMENT', value: <Button variant="contained" sx={{ color: '#fff' }} onClick={handleCheckout}>Pay Now</Button> }
    ];

    /* const configureData = cnameRecords && cnameRecords.map((record: any) =>
        [
            { heading: 'DOMAIN NAME', value: 'MyDomain.com' },
            { heading: 'RECORD TYPE', value: 'CNAME' },
            {
                heading: 'HOST NAME', value: <TextField variant="outlined" sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#9FADBC',
                        },
                        '&:hover fieldset': {
                            borderColor: '#9FADBC',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#9FADBC',
                        },
                        height: 30,
                        marginBottom: '-6%'
                    },
                }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    size="small"
                                    sx={{
                                        marginRight: '8px',
                                        background: '#000',
                                        borderRadius: '3px',
                                        padding: '5%',
                                        width: '140%',
                                        fontSize: '10px',
                                        '&:hover': {
                                            background: '#000', // Optional: Add hover effect for better UX
                                        },
                                    }}
                                //   onClick={() => handleCopyName(record.name)}
                                >
                                    Copy
                                </IconButton>
                            </InputAdornment>
                        ),
                    }} value={record.name}></TextField>
            },
            {
                heading: 'REQUIRED VALUE', value: <TextField variant="outlined" sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: '#9FADBC',
                        },
                        '&:hover fieldset': {
                            borderColor: '#9FADBC',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#9FADBC',
                        },
                        height: 30,
                        marginBottom: '-6%'
                    },
                }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    size="small"
                                    sx={{
                                        marginRight: '8px',
                                        background: '#000',
                                        borderRadius: '3px',
                                        padding: '5%',
                                        width: '140%',
                                        fontSize: '10px',
                                        '&:hover': {
                                            background: '#000', // Optional: Add hover effect for better UX
                                        },
                                    }}
                                //   onClick={() => handleCopyVal(record.value)}
                                >
                                    Copy
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    value={record.value}></TextField>
            },
            { heading: 'VERIFY', value: record.verified === true ? <div style={{ border: '1px solid #027A48', borderRadius: '20px', padding: '5%', textAlign: 'center', background: '#A6F4C5' }}><span style={{ color: '#027A48', fontWeight: 'bold', fontSize: '16px' }}><VerifiedIcon />Verified</span></div> : <div style={{ border: '1px solid #D43131', borderRadius: '20px', padding: '5%', textAlign: 'center' }}><span style={{ color: '#D43131', fontWeight: 'bold', fontSize: '16px' }}>Non-verified</span></div> }
        ]
    ) */

    const handledropdownChange = (event: any) => {
        setSelectedSection(event.target.value);
    }

    const handleSourceUrlChange = (event: any) => {
        let urlVal = event.target.value;
        if (urlVal.startsWith('https://')) {
            urlVal = urlVal.replace('https://', '');
        }
        setSourceUrl(urlVal);
        localStorage.setItem('sourceurl', urlVal);
    }

    const handleSourceTextChange = (event: any) => {
        let textVal = event.target.value;
        setSourceText(textVal);
        localStorage.setItem('sourcetext', textVal);
    }

    const handlePodcastUrlChange = (event: any) => {
        let podcastVal = event.target.value;
        setPodcastOrYoutubeUrl(podcastVal);
        localStorage.setItem('podcastUrl', podcastVal);
    }

    useEffect(() => {
        if (sourceUrl) {
            setVideoTag(sourceUrl);
        } else if (planDetails?.videotag) {
            setVideoTag(planDetails?.videotag);
        } else if (planDetails?.videoid && !videoTag) {
            setVideoTag(`https://www.youtube.com/watch?v=${planDetails.videoid}`);
        }
    }, [planDetails, sourceUrl]);

    const handlevideotag = (event: any) => {
        let videotagg = event.target.value;
        setVideoTag(videotagg);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                videotag: videotagg
            })
        }
        localStorage.setItem('videoTag', videotagg);
    }

    const handletwitterUrlChange = (event: any) => {
        let urlVal = event.target.value;
        settwitterUrl(urlVal);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                twitterurl: urlVal
            });
        }
        localStorage.setItem('twitterurl', urlVal);
    }

    const handlefbUrlChange = (event: any) => {
        let urlVal = event.target.value;
        setfbUrl(urlVal);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                fburl: urlVal
            });
        }
        localStorage.setItem('fburl', urlVal);
    }

    const handlepintrestUrlChange = (event: any) => {
        let urlVal = event.target.value;
        setPintrestUrl(urlVal);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                pintresturl: urlVal
            });
        }
        localStorage.setItem('pintresturl', urlVal);
    }

    const handleCtaLabelChange = (event: any) => {
        let label = event.target.value;
        setCtaLabel(label);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                cta_lable: label
            });
        }
    }

    const handleCtaLinkChange = (event: any) => {
        let link = event.target.value;
        setCtaLink(link);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                cta_link: link
            });
        }
    }

    const handlelinkedinUrlChange = (event: any) => {
        let urlVal = event.target.value;
        setLinkedinUrl(urlVal);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                linkedinurl: urlVal
            });
        }
        localStorage.setItem('linkedinurl', urlVal);
    }


    const handlepaymentOptions = (value: any, subscription_level: any) => {
        // const payment_Option = event?.target.value;
        setPaymentOption(value);
        setSelectedSubscriptionLevel(subscription_level);
        console.log('selecting subscription=>', selectedSubscriptionLevel);
        console.log('selected payment val=>', paymentOption);
        setPaymentOptYearlyCal(value === '$12/mo.' && period === 'yearly' ? '$' + (12 * 12).toFixed(1) + '/yr.' : value === '$41/mo.' ? '$' + (41 * 12).toFixed(1) + '/yr.' : value === '$83/mo.' ? '$' + (83 * 12).toFixed(1) + '/yr.' : value === '$124/mo.' ? '$' + (124 * 12).toFixed(1) + '/yr.' : value)
        localStorage.setItem('paymentOption', value);
        localStorage.setItem('PaymentSubscription', subscription_level);

        if (subscription_level == 'level_0' || subscription_level == 'level_0y' || subscription_level == 'level_1'
            || subscription_level == 'level_2' || subscription_level == 'level_4' || subscription_level == 'level_5') {

            setExpanded3(false);
            setExpanded6(false);
            setExpanded7(false);
            setExpanded8(false);
            setExpanded9(false);

            setSocialMediaUrlPublish(false)
            setCapturLeads(false);
            setNewsLetter(false);
            setTwitternButton(false);
            setFbButton(false);
            setPintrestButton(false);
            setLinkedinButton(false);

            settwitterUrl('');
            setfbUrl('');
            setPintrestUrl('');
            setLinkedinUrl('');
        }
        else {

            setCapturLeads(true);
            setNewsLetter(true);
            setTwitternButton(true);
            setFbButton(true);
            setPintrestButton(true);
            setLinkedinButton(true);
        }

    }

    const paymentSubscription = localStorage.getItem('PaymentSubscription') || '';

    /* const handlepaymentOptions1 = (event: any) => {
        const payment_Option = event?.target.value;
        setPaymentOption(payment_Option);
        localStorage.setItem('paymentOption', payment_Option);
    }

    const handlepaymentOptions2 = (event: any) => {
        const payment_Option = event?.target.value;
        setPaymentOption(payment_Option);
        localStorage.setItem('paymentOption', payment_Option);
    }

    const handlepaymentOptions3 = (event: any) => {
        const payment_Option = event?.target.value;
        setPaymentOption(payment_Option);
        localStorage.setItem('paymentOption', payment_Option);
    }

    const handlepaymentOptions4 = (event: any) => {
        const payment_Option = event?.target.value;
        setPaymentOption(payment_Option);
        localStorage.setItem('paymentOption', payment_Option);
    }

    const handlepaymentOptions5 = (event: any) => {
        const payment_Option = event?.target.value;
        setPaymentOption(payment_Option);
        localStorage.setItem('paymentOption', payment_Option);
    } */

    const handleAutoPublishChange = (event: any) => {
        const isChecked = event.target.checked;
        setAutopublish(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                autopublish: isChecked
            });
        }
        localStorage.setItem('autoPublish', isChecked.toString());
    };

    const handlelimitPublishChange = (event: any) => {
        const isChecked = event.target.checked;
        setLimitPublish(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                threeblogperday: isChecked
            });
        }
        localStorage.setItem('limitPublish', isChecked.toString());
    };

    const handlesocialMediaUrlPublishChange = (event: any) => {
        if (planDetails)
            if (planDetails?.subscription_level != "level_3" && planDetails?.subscription_level != "level_6") {
                return false;
            }
        const isChecked = event.target.checked;
        setSocialMediaUrlPublish(isChecked);
        if (planDetails) {
            // Update planDetails if it exists (you can add specific updates if needed)
            setPlanDetails({
                ...planDetails,
                // Here you can add any relevant updates to planDetails based on isChecked
                // For example:
                socialMediaUrlPublish: isChecked
            });
        }
        localStorage.setItem('smUrlpublish', isChecked.toString());
    };

    const handleaddtagsChange = (event: any) => {
        const isChecked = event.target.checked;
        setAddTags(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                addtags: isChecked
            });
        }
        localStorage.setItem('addTags', isChecked.toString());
    };

    const handlecapturleadsChange = (event: any) => {
        const isChecked = event.target.checked;
        setCapturLeads(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                capturleads: isChecked
            });
        }
        localStorage.setItem('captureLeads', isChecked.toString());
    };

    const handlenewsletterChange = (event: any) => {
        const isChecked = event.target.checked;
        setNewsLetter(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                newsletter: isChecked
            });
        }
        localStorage.setItem('newsLetter', isChecked.toString());
    };

    const handletwitterBtnChange = (event: any) => {
        const isChecked = event.target.checked;
        setTwitternButton(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                twitternbutton: isChecked
            });
        }
        localStorage.setItem('twitterBtn', isChecked.toString());
    };

    const handlefbBtnChange = (event: any) => {
        const isChecked = event.target.checked;
        setFbButton(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                fbbutton: isChecked
            });
        }
        localStorage.setItem('fbBtn', isChecked.toString());
    };

    const handlepintrestBtnChange = (event: any) => {
        const isChecked = event.target.checked;
        setPintrestButton(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                pintrestbutton: isChecked
            });
        }
        localStorage.setItem('pintrestBtn', isChecked.toString());
    };

    const handlelinkedinBtnChange = (event: any) => {
        const isChecked = event.target.checked;
        setLinkedinButton(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                linkedinbutton: isChecked
            });
        }
        localStorage.setItem('linkedinBtn', isChecked.toString());
    };

    const handlevideoChange = (event: any) => {
        const isChecked = event.target.checked;
        setVideo(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                video: isChecked
            });
        }
        localStorage.setItem('video', isChecked.toString());
    };

    const handleOwnDomainChange = (event: any) => {
        const isChecked = event.target.checked;
        setUseOwnDomain(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                owndomain: isChecked
            });
        }
        localStorage.setItem('owndomain', isChecked.toString());
    };

    const handleAuthorUseDefaultChange = (event: any) => {
        const isChecked = event.target.checked;
        setUseAsDefault(isChecked);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                use_as_default: isChecked
            });
        }
        localStorage.setItem('authorDefaultCheck', isChecked.toString());
    }

    const handleModalPayNowClick = () => {
        setOpenPaymentInstructModal(false);
        setExpanded(true);
        setTimeout(() => {
            paymentSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }, 300);
    };

    const campaignIdfromsuccess = localStorage.getItem('campaignid');

    const handleGetPageforCampaign = async () => {
        if (!campIdFrEdit) {
            setLoadingText("Creating your blog...");
            setIntervalTime(4000);
            setIsLoadingGetPage(true);
        }
        try {

            const response = await getPageforCampaign({ email: user_email, campaign_id: campaignIdfromsuccess || '' });
            console.log("response-------------");
            console.log(response);
            if ('data' in response) {
                //console.log('getpagecampaign=>', response.data.result);
                console.log("campIdFrEdit-------", campIdFrEdit);

                if (response.data.result === 'success' && !campIdFrEdit) {
                    setLoaderProgress(100);
                    setTimeout(() => {
                        setIsLoadingGetPage(false);
                        setOpenGetPageSuccessModal(true);
                    }, 2000);
                } else if (response.data.result === 'failure') {
                    setGetPageError(true);
                    toast.error(response.data.message);
                }
            }
            else {
                const errorMessage = (response?.error as any)?.data?.detail as string | undefined;
                toast.error(errorMessage);
                setIsLoadingGetPage(false);
            }
        } catch (error) {
            toast.error('Error generating blogs');
            setIsLoadingGetPage(false);
            console.log('Error generating blogs:', error);
        }
    }

    useEffect(() => {
        if (campaignIdfromsuccess && !getpageRunref.current) {
            handleGetPageforCampaign();
            getpageRunref.current = true;
        }
    }, [campaignIdfromsuccess])

    const handleGetPageModalClose = async () => {
        setOpenGetPageSuccessModal(false);
        navigate('/codegenie/generated-blogs', { state: { FromCampaign: campaignIdfromsuccess } });
        // setExpanded3(true);
        // setTimeout(() => {
        //     getcnameEntriesRef?.current?.scrollIntoView({ behavior: 'smooth' });
        // }, 300);
        setIsLoadingCnameRec(true);
        // try {
        //     const response = await getCnameEntriesMutation({ email: user_email, campaignid: campaignIdfromsuccess || '' });
        //     if ('data' in response) {
        //         console.log('cnameentries=>', response.data.cnamerecords);
        //         setCnameDomainName(response.data);
        //         setCnameRecords(response.data.cnamerecords);
        //     }
        // } catch (error) {
        //     console.log('Error fetching dns record:', error);
        // } finally {
        //     setIsLoadingCnameRec(false);
        //     getVerifyingScript(campaignIdfromsuccess || '');
        //     // localStorage.removeItem('campaignid');
        // }
    }

    const getVerifyingScript = async (campaignId: string) => {
        // setExpanded4(true);
        /*  setTimeout(() => {
             getVerifyScript?.current?.scrollIntoView({ behavior: 'smooth' });
         }, 300); */
        try {
            const response = await getverifyscript({ userid: user_email, campaingid: campaignId || ''/* campaignId ? campaignId : campaignIdfromsuccess || '' */ });
            if ('data' in response) {
                console.log('cnameentries=>', response.data.script);
                setScript(response.data.script);
            }
        } catch (error) {
            console.log('fetching error', error);
        }
    }

    const handleVerifyCheckClick = async (campaignId: string) => {
        try {
            const response = await getcheckverified({ userid: user_email, campaingid: campaignId/* localStorage.getItem('campaignid') */ || '' });
            if ('data' in response) {
                console.log('cnameentries=>', response.data.status);
                setVerifyCheck(response.data.status);
                localStorage.removeItem('campaignid');
            }
        } catch (error) {
            console.log('fetching error', error);
        }
    }

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const imageUrl = URL.createObjectURL(file);
            setUserImage(file/* e.target.files[0] */);
            setUserImageUrl(imageUrl);

            return () => URL.revokeObjectURL(imageUrl);
        }
    };

    const handleUpdateSetting = async (campaignId: string) => {
        // e.preventDefault();
        setIsUpdateLoader(true);
        let formData = new FormData();

        // let isFormValid = validateForm();
        // if (isFormValid) {
        //   setIsLoading(true);
        formData.append('name', name ? name : planDetails?.name === undefined ? '' : planDetails.name/* planDetails?.name || '' */);
        formData.append('campaignid', campaignId);
        if (userImage) {
            formData.append('user_image', userImage);
        }
        formData.append('social_url', socialUrl);
        formData.append('use_as_default', useAsDefault ? '1' : '0');
        //   formData.append('blog_default', blogDefault ? '1' : '0');
        //   formData.append('email', useremail);
        //   formData.append('auto_publish', isChecked ? `${isChecked}` : "0");
        /* if (!name || !campaignId) {
            toast.error('Please fill the required field on author section!');
        } */
        // else {
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + '/api/prompts/updateAuthorinfo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status == "success") {
                //   toast.success(response.data.message);
                //   setIsLoading(false);
                const randomNumber = Math.random();
                setUserImageUrl(response.data.user_image + "?" + randomNumber);
                /* if (FromEdit === 'startEditing') {
                    toast.success(response.data.message);
                } */
            }
            else {
                toast.error("Somethng went wrong..")
                //   setIsLoading(false);
            }
            console.log(response.data);
        } catch (error) {
            console.error('Error updating author settings:', error);
            // setIsLoading(false);
        } finally {
            setIsUpdateLoader(false);
        }
        // }

        // }

    };

    useEffect(() => {
        const storedCampaignName = localStorage.getItem('campaignName');
        if (storedCampaignName) {
            setCampaignName(storedCampaignName);
        }

        const storedAuthorName = localStorage.getItem('Authorname');
        if (storedAuthorName) {
            setName(storedAuthorName);
        }

        const storedAuthorurl = localStorage.getItem('Authorurl');
        if (storedAuthorurl) {
            setSocialUrl(storedAuthorurl);
        }

        const storedSourceUrl = localStorage.getItem('sourceurl');
        if (storedSourceUrl) {
            setSourceUrl(storedSourceUrl);
        }

        const storedSourceText = localStorage.getItem('sourcetext');
        if (storedSourceText) {
            setSourceText(storedSourceText);
        }

        const storedPodcastUrl = localStorage.getItem('podcastUrl');
        if (storedPodcastUrl) {
            setPodcastOrYoutubeUrl(storedPodcastUrl);
        }

        const storedtwitterUrl = localStorage.getItem('twitterurl');
        if (storedtwitterUrl) {
            settwitterUrl(storedtwitterUrl);
        }

        const storedFBUrl = localStorage.getItem('fburl');
        if (storedFBUrl) {
            setfbUrl(storedFBUrl);
        }

        const storedpintrestUrl = localStorage.getItem('pintresturl');
        if (storedpintrestUrl) {
            setPintrestUrl(storedpintrestUrl);
        }

        const storedLinkedinUrl = localStorage.getItem('linkedinurl');
        if (storedLinkedinUrl) {
            setLinkedinUrl(storedLinkedinUrl);
        }

        const payment_opt = localStorage.getItem('paymentOption');
        if (payment_opt) {
            setPaymentOption(payment_opt);
        }

        const storedAutoPublish = localStorage.getItem('autoPublish');
        if (storedAutoPublish !== null) {
            setAutopublish(storedAutoPublish === 'true');
        }

        const storedLimitPublish = localStorage.getItem('limitPublish');
        if (storedLimitPublish !== null) {
            setLimitPublish(storedLimitPublish === 'true');
        }

        const storedsocialMediaurlsPublish = localStorage.getItem('smUrlpublish');
        if (storedsocialMediaurlsPublish !== null) {
            setSocialMediaUrlPublish(storedsocialMediaurlsPublish === 'true');
        }

        const storedAddTags = localStorage.getItem('addTags');
        if (storedAddTags !== null) {
            setAddTags(storedAddTags === 'true');
        }

        const storedcaptureLeads = localStorage.getItem('captureLeads');
        if (storedcaptureLeads !== null) {
            setCapturLeads(storedcaptureLeads === 'true');
        }

        const storednewsLetter = localStorage.getItem('newsLetter');
        if (storednewsLetter !== null) {
            setNewsLetter(storednewsLetter === 'true');
        }

        const storedtwitterBtn = localStorage.getItem('twitterBtn');
        if (storedtwitterBtn !== null) {
            setTwitternButton(storedtwitterBtn === 'true');
        }

        const storedfbBtn = localStorage.getItem('fbBtn');
        if (storedfbBtn !== null) {
            setFbButton(storedfbBtn === 'true');
        }

        const storedpintrestBtn = localStorage.getItem('pintrestBtn');
        if (storedpintrestBtn !== null) {
            setPintrestButton(storedpintrestBtn === 'true');
        }

        const storedlinkedinBtn = localStorage.getItem('linkedinBtn');
        if (storedlinkedinBtn !== null) {
            setLinkedinButton(storedlinkedinBtn === 'true');
        }

        const storedvideo = localStorage.getItem('video');
        if (storedvideo !== null) {
            setVideo(storedvideo === 'true');
        }

        const storedAuthorDefCheck = localStorage.getItem('authorDefaultCheck');
        if (storedAuthorDefCheck !== null) {
            setUseAsDefault(storedAuthorDefCheck === 'true');
        }
    }, []);

    const handleCampaignNameChange = (event: any) => {
        const newName = event.target.value;
        if (newName === planDetails?.product_name) {
            toast.error('Already campaign name exist!');
        } else {
            setCampaignName(newName);
            if (planDetails) {
                setPlanDetails({
                    ...planDetails,
                    product_name: newName
                });
            }
            localStorage.setItem('campaignName', newName);
        }
    };

    const handleAuthorNameChange = (event: any) => {
        const newName = event.target.value;
        setName(newName);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                name: newName
            });
        }
        if (userDetails) {
            setUserDetails({
                ...userDetails,
                name: newName
            });
        }
        localStorage.setItem('Authorname', newName);
    }

    const handleAuthorUrlChange = (event: any) => {
        const newUrl = event.target.value;
        setSocialUrl(newUrl);
        if (planDetails) {
            setPlanDetails({
                ...planDetails,
                social_url: newUrl
            });
        }
        localStorage.setItem('Authorurl', newUrl);
    }

    const handleCopyName = (name: string) => {
        navigator.clipboard.writeText(name).then(() => {
            toast.success('Copied to Clipboard!');
        })
    }

    const handleCopyscript = (script: string) => {
        navigator.clipboard.writeText(script).then(() => {
            toast.success('Copied to Clipboard!');
        })
    }

    const handleCopyVal = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            toast.success('Copied to Clipboard!');
        })
    }

    const placeholderText = 'https://www.youtube.com/watch?v=X7sEkixKk4g&t=1s';

    const code_snippet = script/* `<!- Traffic Monster tag (gtag.js) --> <script async src=https://www.googletagmanage EGRK3ZCMNX"></script> <script> window.dataLayer =
window.dataLayer || []; function gtag() {dataLayer.push(arguments) i) gtag('is' new Date gtag('config', 'G-EGRKзZCMNX');` */;

    /* const handleShare = (url: any) => {
        window.open(url, '_blank');
    };

    const shareOnTwitter = () => {
        handleShare(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`);
    };

    const shareOnPinterest = () => {
        handleShare(`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}`);
    };

    const shareOnFacebook = () => {
        handleShare(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`);
    };

    const shareOnLinkedin = () => {
        handleShare(`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}`);
        handleShare(`https://www.linkedin.com/sharing/share-offsite/url=${encodeURIComponent(window.location.href)}`);

    }; */

    useEffect(() => {
        setUserImageUrl(planDetails?.user_image);
    }, [planDetails]);

    const NormallyValArray = [period == "monthly" ? '' : 'Normally $14/mo.', period == "monthly" ? '' : 'Normally $49/mo.', period == "monthly" ? '' : 'Normally $99/mo.', period == "monthly" ? '' : 'Normally $149/mo.'];

    const handleChangePayMethodClick = async () => {
        localStorage.setItem('ChangepayMode', 'true');
        localStorage.setItem('IdChangingMode', campIdFrEdit);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + `/api/subscription/change_payment_method?email=${user_email}`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === 'success') {
                window.open(response.data.portal_url, '_blank');
            }
        } catch (error) {
            console.log('Error changing payment method', error);
        }
    }

    const handleCancelSubscriptionClick = async () => {
        setCancellingLoader(true);
        try {
            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            const response = await axios.post(apiUrl + `/api/subscription/Cancel_Subscription?campaign_id=${campIdFrEdit}`, {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            });
            if (response.data.status === 'success') {
                setSuccessCancelSubscription(true);
                setConfirmCancelSubscription(false);
                toast.success('Cancelled Subscription!');
            }
        } catch (error) {
            toast.error('Error cancelling subscription');
            console.log('Error cancelling subscription', error);
        } finally {
            setCancellingLoader(false);
        }
    }

    const currentlocationurl = window.location.origin;
    const addtoMyblogscodeSnippet =
        `<iframe src="${currentlocationurl}/blogs?campaignid=${campIdFrEdit}&header=off" height="600" width="900" title="Iframe Example"></iframe>`;

    const handleCopyCodeSnippet = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            toast.success('Copied to Clipboard!');
        })
    }

    const handleuploadvideoChange = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setUploadVideo(file.name);
            console.log('Uploaded video file:', file);
            const formData = new FormData();
            formData.append('media_file', file);

            const apiUrl: string = process.env.REACT_APP_SERVER_ENDPOINT || '';
            setIsUploadLoading(true);
            try {
                const response = await axios.post(
                    `${apiUrl}/api/prompts/upload_media_file`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                console.log(response.data.data);
                if (response.data.result === 'success' && response.data.data) {
                    setUploadedvideopath(response.data.data);
                    toast.success('File Uploaded Successfully!');
                }
            } catch (error) {
                console.error('Error uploading video:', error);
                toast.error('Error uploading video');
            } finally {
                setIsUploadLoading(false);
            }

        }
    };

    return (
        <>
            <Header />
            <div style={{ margin: '7%' }}>
                {isloadingGetPage && !getPageError && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        zIndex: 9999,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backdropFilter: 'blur(5px)',
                        overflow: 'hidden',
                    }}>

                        <div style={{
                            width: '80%', maxWidth: '700px',
                            padding: '20px',
                            backgroundColor: '#282C39',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            textAlign: 'center'
                        }}>
                            <Typography style={{ fontSize: '30px' }}>{loadingText}{/* Generating Blogs with AI... */}</Typography>
                            <br></br>
                            {/* <LinearProgress /> */}
                            <ProgressBar
                                completed={loaderProgress}
                                bgColor={loaderProgress >= 90 ? "#4CAF50" : "#4BA5EB"}
                                height="20px"
                                labelColor="#ffffff"
                                labelAlignment="center"
                            />
                            <br></br>
                            <Typography style={{ fontSize: '20px' }}>This may take a couple minutes...</Typography>
                        </div>
                    </div>
                )}

                {/* {(subscriptionFromCampList || planDetails?.mode == "Free Trial" || defaultPlanDetails?.mode == "Free Trial") ?
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ background: '#090619', borderRadius: '5px', padding: isMobile ? '6%' : '2%', width: isMobile ? '100%' : '50%', }}>
                                <Typography sx={{ fontSize: '15px', fontWeight: 'bolder', color: "#fff" }}><span style={{ display: 'flex', justifyContent: 'center', marginBottom: '2%' }}><InfoIcon style={{ color: 'Orange', fontSize: '30px' }} /></span><span>{(((planDetails?.subscription_level == 'level_3' || planDetails?.subscription_level == 'level_6') && planDetails?.mode == "Free Trial") || defaultPlanDetails?.mode == "Free Trial") ? 'Note: Traffic Hacker plan NOT ACTIVATED.  You can view this page but you will not be able to create new campaigns/update existing campaign with all features, until you have activated your Traffic Hacker plan.' : 'Note: You can view this page but you will not be able to create new campaigns until you upgrade to Traffic Hacker plan.'}</span></Typography>
                            </Box>
                        </Grid>
                    </Grid> : ''} */}

                {planDetails?.payment_status === 'pending' ?
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Box sx={{ background: '#090619', borderRadius: '5px', padding: isMobile ? '6%' : '2%', width: isMobile ? '100%' : '50%', }}>
                                <Typography sx={{ fontSize: '15px', fontWeight: 'bolder', color: "#fff" }}><span style={{ display: 'flex', justifyContent: 'center', marginBottom: '2%' }}><InfoIcon style={{ color: 'Orange', fontSize: '30px' }} /></span><span style={{display: 'flex', justifyContent: 'center'}}>{'Note: Subscription is PENDING, please complete the payment.'}</span></Typography>
                            </Box>
                        </Grid>
                    </Grid> : ''}

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: '32px', fontWeight: 'bolder' }}>{(FromEdit === 'startEditing' || localStorage.getItem('ChangepayMode') === 'true') ? 'Edit Campaign' : 'New Campaign'}</Typography>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{
                                width: '100%',
                                height: '40px',
                                fontSize: 'larger',
                                '.MuiInputBase-root': {
                                    height: '100%',
                                    '& fieldset': {
                                        border: 'none',
                                    }
                                }
                            }} value={campaignName} />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '1%' }}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Campaign Name<span style={{ color: 'red' }}>*</span></Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField sx={{
                            width: '100%',
                            height: '40px',
                            '.MuiInputBase-root': {
                                height: '100%',
                            },
                            background: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            pointerEvents: FromEdit ? 'none' : 'block'
                        }}
                            InputProps={{
                                readOnly: FromEdit
                            }}
                            value={planDetails ? planDetails?.product_name : campaignName} onChange={handleCampaignNameChange} />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded1 ? '500px' : '100px'
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded1 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick1}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    {/* selectedSection === 'podcastSection' ? */
                                        "YouTube Video URL/Upload Video"/*  : "Source Page URL" */
                                    }<span style={{ color: 'red' }}>*</span>
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        {selectedSection === 'podcastSection' ?
                                            "The blogs and content will be created based on the content from this podcast or video."
                                            :
                                            "The blogs and content will be created based on the content from this page."
                                        }
                                    </Typography>
                                </Box>
                                <Box>
                                    {expanded1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded1} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff'
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={2} sx={{
                                            color: '#344054', display: urlPrefix === 'text' || planDetails?.source === 'text' ? '' : 'flex',
                                            alignItems: urlPrefix === 'text' || planDetails?.source === 'text' ? '' : 'center'
                                        }}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    padding: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            >
                                                <Select
                                                    /* defaultValue="https" */
                                                    value={planDetails?.source === 'upload' ? 'upload' : planDetails?.source === 'youtube' ? 'https://' : urlPrefix}
                                                    onChange={(event) => {
                                                        if (planDetails?.source !== 'upload' && planDetails?.source !== 'youtube') {
                                                            setUrlPrefix(event.target.value);
                                                        }
                                                    }}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    sx={{
                                                        width: '100%',
                                                        height: '35px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%'
                                                        },
                                                        backgroundColor: 'white',
                                                        color: '#2A313A',
                                                        '& .MuiSelect-icon': {
                                                            color: '#2A313A',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="https://">https://</MenuItem>
                                                    <MenuItem value="upload">Upload Video File</MenuItem>
                                                </Select>
                                            </Paper>
                                        </Grid>

                                        <Grid item xs={12} sm={10}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    padding: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            >
                                                {urlPrefix === 'upload' || planDetails?.source === 'upload' ? (
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                        <input
                                                            type="file"
                                                            accept="video/*"
                                                            style={{
                                                                width: '100%',
                                                                // height: '200px',
                                                                height: '35px',
                                                                borderColor: '#CBCBCB',
                                                                borderRadius: '5px',
                                                                padding: '4px 10px',
                                                                lineHeight: '23px'
                                                            }}
                                                            onChange={handleuploadvideoChange}
                                                        />
                                                        {isUploadLoading ? <CircularProgress size={35} style={{ marginRight: '1%' }} /> : null}
                                                    </div>)
                                                    :
                                                    <TextField
                                                        placeholder={/* selectedSection === 'podcastSection' ?
                                                            "Enter your YouTube Podcast/Video url" :  */"Enter youtube url"}
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                            width: '100%',
                                                            height: '35px',
                                                            '.MuiInputBase-root': {
                                                                height: '100%'
                                                            },
                                                            pointerEvents: FromEdit ? 'none' : 'block'
                                                        }}
                                                        InputProps={{
                                                            readOnly: FromEdit,
                                                        }}
                                                        value={planDetails ? `www.youtube.com/watch?v=${planDetails.videoid}` : sourceUrl}
                                                        onChange={handleSourceUrlChange}
                                                        id="sourceurl"
                                                    // disabled={Boolean(podcastoryoutubeurl)}
                                                    ></TextField>}
                                            </Paper>
                                        </Grid>

                                        {/* <Grid item xs={12} sm={3}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    padding: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            >
                                                <Select
                                                    value={selectedSection}
                                                    onChange={handledropdownChange}
                                                    IconComponent={KeyboardArrowDownIcon}
                                                    sx={{
                                                        width: '100%',
                                                        height: '35px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%'
                                                        },
                                                        backgroundColor: 'white',
                                                        color: '#2A313A',
                                                        '& .MuiSelect-icon': {
                                                            color: '#2A313A',
                                                        },
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: 'transparent',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem value="websiteSection">Your Website URL</MenuItem>
                                                    <MenuItem value="podcastSection">Your Podcast/Video</MenuItem>
                                                </Select>
                                            </Paper>
                                        </Grid> */}
                                    </Grid>

                                    {/* {selectedSection === 'podcastSection' ?
                                        <Grid container spacing={2} sx={{ marginTop: '1px' }}>
                                            <Grid item xs={12} sm={12}>
                                                <Paper
                                                    elevation={3}
                                                    sx={{
                                                        padding: 1,
                                                        backgroundColor: 'white',
                                                        borderRadius: '4px',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <TextField
                                                        placeholder="Enter your podcast/youtube video url"
                                                        sx={{
                                                            backgroundColor: '#fff',
                                                            width: '100%',
                                                            height: '35px',
                                                            '.MuiInputBase-root': {
                                                                height: '100%'
                                                            },
                                                            color: '#4BA5EB',
                                                            pointerEvents: FromEdit ? 'none' : 'block'
                                                        }}
                                                        InputProps={{
                                                            readOnly: FromEdit,
                                                        }}
                                                        value={ podcastoryoutubeurl}
                                                        onChange={handlePodcastUrlChange}
                                                        // disabled={Boolean(podcastoryoutubeurl)}
                                                    ></TextField>
                                                </Paper>
                                            </Grid>
                                        </Grid> : ''} */}

                                    {/* <Grid container spacing={2} sx={{ marginTop: '1px' }}>
                                        <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="h6" sx={{ fontWeight: 'bolder', color: '#000' }}>OR</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} sx={{ marginTop: '1px' }}>
                                        <Grid item xs={12} sm={12}>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    padding: 1,
                                                    backgroundColor: 'white',
                                                    borderRadius: '4px',
                                                    width: '100%',
                                                }}
                                            >
                                                <TextField
                                                    placeholder="Enter your podcast/youtube video url"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '35px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%'
                                                        },
                                                        pointerEvents: FromEdit ? 'none' : 'block'
                                                    }}
                                                    InputProps={{
                                                        readOnly: FromEdit
                                                    }}
                                                    value={ podcastoryoutubeurl}
                                                    onChange={handlePodcastUrlChange}
                                                    disabled={Boolean(sourceUrl)}></TextField>
                                            </Paper>
                                        </Grid>
                                    </Grid> */}
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded ? (isMobile ? '100%' : '100%') : '100px'
                            }} ref={paymentSectionRef}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '140px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Payment<span style={{ color: 'red' }}>*</span>
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        Payment needed to initiate campaign
                                    </Typography>
                                </Box>
                                {(FromEdit === 'startEditing' || localStorage.getItem('ChangepayMode') === 'true') && planDetails?.subscription_level ?
                                    <>
                                        <Box sx={{ textAlign: 'left', gap: '10px', display: 'flex', marginLeft: planDetails?.mode === 'Canceled' ? '65%' : '44%' }}>
                                            {planDetails?.mode !== 'Paid' || planDetails?.payment_status !== 'paid' ? '' :
                                                <Button variant='contained' sx={{
                                                    color: '#000', fontWeight: 'bold', '&.Mui-disabled': {
                                                        color: '#000',
                                                        backgroundColor: '#4BA5EB',
                                                        cursor: 'not-allowed'
                                                    }
                                                }} onClick={/* handleCancelSubscriptionClick */() => setConfirmCancelSubscription(true)} disabled={successCancelSubscription}>{/* cancellingLoader ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : */ /* successCancelSubscription */planDetails?.mode === 'Canceled' ? 'Cancelled' : 'Cancel Subscription'}</Button>
                                            }
                                            {planDetails?.mode !== 'Paid' || planDetails?.payment_status !== 'paid' ? '' : <Button variant='contained' sx={{ color: '#000', fontWeight: 'bold' }} onClick={handleChangePayMethodClick}>Change Payment Method</Button>}
                                        </Box>
                                    </>
                                    : ''}
                                <Box>
                                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded} style={{ marginTop: '2%' }}>
                                {!FromEdit && localStorage.getItem('ChangepayMode') !== 'true' || planDetails?.payment_status === 'pending' ?
                                    <Box
                                        sx={{
                                            padding: '16px',
                                            background: '#f9f9f9',
                                            borderRadius: '0 0 8px 8px',
                                            backgroundColor: '#fff'
                                        }}
                                    >
                                        {/* <div className="billingBtn"> */}
                                        <Box component="div" textAlign="center" sx={{ p: 2 }}>

                                            <div style={{ background: '#000', width: isMobile ? '70%' : '30%', margin: '0 auto', padding: '1%' }}>
                                                <Button
                                                    size="large"
                                                    // variant={period==="yearly"?"contained":"text"}
                                                    sx={{
                                                        // background: '#000',
                                                        // background: `${period === "yearly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                                                        color: `${period === "yearly" ? "#fff" : "inherit"}`,
                                                        paddingX: 2,
                                                        paddingY: 1,
                                                        borderBottom: `${period === "yearly" ? "3px solid #fff" : "none"}`,
                                                        borderRadius: '0px',
                                                        fontSize: 'large'
                                                    }}
                                                    onClick={() => {
                                                        getPeriodState("yearly")
                                                    }}

                                                >
                                                    Yearly Discount
                                                </Button>
                                                <Button
                                                    size="large"
                                                    // variant={period==="monthly"?"contained":"text"}
                                                    sx={{
                                                        // background: '#000',
                                                        // background: `${period === "monthly" ? "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)" : ""}`,
                                                        color: `${period === "monthly" ? "#fff" : "inherit"}`,
                                                        paddingX: 2,
                                                        paddingY: 1,
                                                        borderBottom: `${period === "monthly" ? "3px solid #fff" : "none"}`,
                                                        borderRadius: '0px',
                                                        fontSize: 'large'
                                                    }}
                                                    onClick={() => {
                                                        getPeriodState("monthly");
                                                    }}
                                                >
                                                    Monthly billing
                                                </Button>
                                            </div>
                                        </Box>
                                        {/* </div> */}
                                        {/* period == 'monthly' ? */ <><span style={{ color: '#000', fontWeight: 'bold' }}>Redeem Coupon:</span> <input className="px-3" style={{ marginBottom: '1%' }} type="text" value={couponCode} onChange={handleCouponTextChange} name="couponcode" id="couponcode" ></input></> /* : '' */}

                                        {plans.filter((item) => item.period === period && item.plan_name !== 'FREE').map((plan, index) => (
                                            <div key={`plan_item_${index}`}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #CBCBCB',
                                                        padding: '1%',
                                                        borderRadius: '6px',
                                                        marginTop: '1%'
                                                    }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={4} sx={{
                                                            color: '#344054', display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                                <CardIcon />
                                                            </span>
                                                            <div>
                                                                <span style={{ fontSize: '16px', display: 'block' }}>{period == "monthly" ? 'Monthly' : 'Yearly'}</span>
                                                                <span style={{ textAlign: 'center', textDecorationLine: 'line-through' }}>{NormallyValArray[index]}</span>
                                                                <span style={{ fontSize: '32px', fontWeight: 'bold', display: 'block' }}>{/* $99/mo */}{/* {NormallyValArray[index]} */}{`$${plan.price + '/mo.'}`}</span>
                                                                <span className="planPrice">{index === 2 && period == "monthly" ? `` : ''/* Number(plan.price).toLocaleString() + `/Month` */}</span>
                                                                <span className="planPrice">{(plan.period === "yearly" && plan.price == "48.8") && "Billed annually (Save $2.4/year)"}{(plan.period === "yearly" && plan.price == "82.5") && "Billed annually (Save $198/year)"}{(plan.period === "yearly" && plan.price == "165.8") && "Billed annually (Save $398/year)"}{(plan.period === "yearly" && plan.price == "249.16") && "Billed annually (Save $2,998/year)"}</span>

                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={7} sm={4} sx={{ color: '#344054' }}>
                                                            {plan.plan_name /* === 'FREE' ? 'Free' : (plan.plan_name === 'Starter' ? 'Basic' : (plan.plan_name === 'PRO' ? 'Traffic Hacker Pro' : (plan.plan_name === 'TRAFFIC HACKER' ? 'Campaign Hacker' : ''))) */}
                                                            <ul>
                                                                <li>Automatic blog creation, SEO, Indexing</li>
                                                                {plan.feature.slice(0, 4).map((features, index) => {
                                                                    // Replace 'white' with 'black' in the HTML content
                                                                    const modifiedFeatures = features.replace(/white/g, 'black');
                                                                    if (index > 0) {
                                                                        return (
                                                                            <li key={index} dangerouslySetInnerHTML={{ __html: modifiedFeatures }}></li>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </ul>
                                                        </Grid>
                                                        <Grid item xs={4} sm={4} sx={{ color: '#344054', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                            <input type="radio" /* value={NormallyValArray[index]} */ value={`$${plan.price + '/mo.'}`}
                                                                /* checked={paymentOption === NormallyValArray[index]} */ checked={paymentOption === `$${plan.price + '/mo.'}`}
                                                                /* onChange={() => handlepaymentOptions(NormallyValArray[index])} */ onChange={() => handlepaymentOptions(`$${plan.price + '/mo.'}`, `${plan.subscription_level}`)} style={{ transform: 'scale(1.5)', width: '13px', height: '13px' }}></input>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </div>
                                        ))}

                                        {/* <Box
                                            sx={{
                                                border: '1px solid #CBCBCB',
                                                padding: '1%',
                                                borderRadius: '6px',
                                                marginTop: '1%'
                                            }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4} sx={{
                                                    color: '#344054', display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                        <CardIcon />
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: '16px', display: 'block' }}>Monthly</span>
                                                        <span style={{ fontSize: '32px', fontWeight: 'bold', display: 'block' }}>$199/mo</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={7} sm={4} sx={{ color: '#344054' }}>
                                                    <ul>
                                                        <li>1 Campaign</li>
                                                        <li>60 Blog/month</li>
                                                        <li>Optionally, use your own domain name</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={4} sm={4} sx={{ color: '#344054', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <input type="radio" value="monthly1" checked={paymentOption === "monthly1"} onChange={handlepaymentOptions1} style={{ transform: 'scale(1.5)', width: '13px', height: '13px' }}></input>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            sx={{
                                                border: '1px solid #CBCBCB',
                                                padding: '1%',
                                                borderRadius: '6px',
                                                marginTop: '1%'
                                            }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4} sx={{
                                                    color: '#344054', display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                        <CardIcon />
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: '16px', display: 'block' }}>Monthly</span>
                                                        <span style={{ fontSize: '32px', fontWeight: 'bold', display: 'block' }}>$299/mo</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={7} sm={4} sx={{ color: '#344054' }}>
                                                    <ul>
                                                        <li>1 Campaign</li>
                                                        <li>90 Blog/month</li>
                                                        <li>Optionally, use your own domain name</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={4} sm={4} sx={{ color: '#344054', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <input type="radio" value="monthly2" checked={paymentOption === "monthly2"} onChange={handlepaymentOptions2} style={{ transform: 'scale(1.5)', width: '13px', height: '13px' }}></input>
                                                </Grid>
                                            </Grid>
                                        </Box> */}


                                        {/* <Box
                                            sx={{
                                                border: '1px solid #CBCBCB',
                                                padding: '1%',
                                                borderRadius: '6px',
                                                marginTop: '5%'
                                            }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4} sx={{
                                                    color: '#344054', display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                        <CardIcon />
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: '16px', display: 'block' }}>Yearly</span>
                                                        <span style={{ fontSize: '32px', fontWeight: 'bold', display: 'block' }}>$82.5/mo<span style={{ fontSize: isMobile ? '15px' : '18px' }}>(Billed Annually)</span></span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={7} sm={4} sx={{ color: '#344054' }}>
                                                    <ul>
                                                        <li>1 Campaign</li>
                                                        <li>30 Blog/month</li>
                                                        <li>Optionally, use your own domain name</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={4} sm={4} sx={{ color: '#344054', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <input type="radio" value="yearly" checked={paymentOption === "yearly"} onChange={handlepaymentOptions3} style={{ transform: 'scale(1.5)', width: '13px', height: '13px' }}></input>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            sx={{
                                                border: '1px solid #CBCBCB',
                                                padding: '1%',
                                                borderRadius: '6px',
                                                marginTop: '1%'
                                            }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4} sx={{
                                                    color: '#344054', display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                        <CardIcon />
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: '16px', display: 'block' }}>Yearly</span>
                                                        <span style={{ fontSize: '32px', fontWeight: 'bold', display: 'block' }}>$165.8/mo<span style={{ fontSize: isMobile ? '15px' : '18px' }}>(Billed Annually)</span></span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={7} sm={4} sx={{ color: '#344054' }}>
                                                    <ul>
                                                        <li>1 Campaign</li>
                                                        <li>60 Blog/month</li>
                                                        <li>Optionally, use your own domain name</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={4} sm={4} sx={{ color: '#344054', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <input type="radio" value="yearly1" checked={paymentOption === "yearly1"} onChange={handlepaymentOptions4} style={{ transform: 'scale(1.5)', width: '13px', height: '13px' }}></input>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box
                                            sx={{
                                                border: '1px solid #CBCBCB',
                                                padding: '1%',
                                                borderRadius: '6px',
                                                marginTop: '1%'
                                            }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4} sx={{
                                                    color: '#344054', display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                        <CardIcon />
                                                    </span>
                                                    <div>
                                                        <span style={{ fontSize: '16px', display: 'block' }}>Yearly</span>
                                                        <span style={{ fontSize: '32px', fontWeight: 'bold', display: 'block' }}>$249.16/mo<span style={{ fontSize: isMobile ? '15px' : '18px' }}>(Billed Annually)</span></span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={7} sm={4} sx={{ color: '#344054' }}>
                                                    <ul>
                                                        <li>1 Campaign</li>
                                                        <li>90 Blog/month</li>
                                                        <li>Optionally, use your own domain name</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={4} sm={4} sx={{ color: '#344054', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    <input type="radio" value="yearly2" checked={paymentOption === "yearly2"} onChange={handlepaymentOptions5} style={{ transform: 'scale(1.5)', width: '13px', height: '13px' }}></input>
                                                </Grid>
                                            </Grid>
                                        </Box> */}
                                    </Box>
                                    : ''}

                                {isMobile ? <Divider /> : ''}

                                {isBrowser ?
                                    <Box sx={{ marginTop: '2%' }}>
                                        <div style={{ overflowX: isMobile ? 'auto' : 'visible' }}>
                                            <Table sx={{ minWidth: 700, width: '100%'/* , marginTop: '2%'  */ }} aria-label="customized table">
                                                <TableHead style={{ backgroundColor: ' #EAECF0', border: "gray" }}>
                                                    <TableRow>
                                                        <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">QUANTITY</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">PLAN TYPE</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", /* width: "20%", */width: '10%', color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">PLAN NAME</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">TOTAL</StyledTableCell>
                                                        {/* <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">PAYMENT</StyledTableCell> */}
                                                        {/* <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">ACTION</StyledTableCell> */}

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {/* {cnameRec && cnameRec.map((record: any, index: any) => ( */}
                                                    <StyledTableRow>
                                                        <StyledTableCell style={{ width: '10%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>1</StyledTableCell>
                                                        <StyledTableCell style={{ width: '20%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{period === 'monthly' ? 'Monthly' : 'Yearly'}</StyledTableCell>
                                                        <StyledTableCell style={{ /* width: '20%', */width: '10%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{planDetails ? (planDetails.subscription_level === 'level_0' || planDetails.subscription_level === 'level_0y' ? 'Starter' : planDetails.subscription_level === 'level_1' || planDetails.subscription_level === 'level_4' ? 'Basic' : planDetails.subscription_level === 'level_2' || planDetails.subscription_level === 'level_5' ? 'Pro' : planDetails.subscription_level === 'level_3' || planDetails.subscription_level === 'level_6' ? 'Podcast Hacker' : '') : selectedSubscriptionLevel ? (selectedSubscriptionLevel === 'level_0' || selectedSubscriptionLevel === 'level_0y' ? 'Starter' : selectedSubscriptionLevel === 'level_1' || selectedSubscriptionLevel === 'level_4' ? 'Basic' : selectedSubscriptionLevel === 'level_2' || selectedSubscriptionLevel === 'level_5' ? 'Pro' : selectedSubscriptionLevel === 'level_3' || selectedSubscriptionLevel === 'level_6' ? 'Podcast Hacker' : '') : ''}</StyledTableCell>
                                                        <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD', color: '#344054', fontWeight: 'bold' }}>
                                                            {planDetails ? (planDetails?.subscription_level === 'level_0' ? '$14/mo.' : planDetails?.subscription_level === 'level_1' ? '$49/mo.' : planDetails?.subscription_level === 'level_2' ? '$99/mo.' : planDetails?.subscription_level === 'level_3' ? '$149/mo.' : planDetails?.subscription_level === 'level_0y' ? '$' + (12 * 12).toFixed(1) + '/yr.' : planDetails?.subscription_level === 'level_4' ? '$' + (41 * 12).toFixed(1) + '/yr.'/* '$82.5/mo.' */ : planDetails?.subscription_level === 'level_5' ? '$' + (83 * 12).toFixed(1) + '/yr.'/* '$165.8/mo.' */ : planDetails?.subscription_level === 'level_6' ? '$' + (124 * 12).toFixed(1) + '/yr.'/* '$249.16/mo.' */ :
                                                                (paymentSubscription === 'level_0' ? '$14/mo.' : paymentSubscription === 'level_1' ? '$49/mo.' : paymentSubscription === 'level_2' ? '$99/mo.' : paymentSubscription === 'level_3' ? '$149/mo.' : paymentSubscription === 'level_0y' ? '$' + (12 * 12).toFixed(1) + '/yr.' : paymentSubscription === 'level_4' ? '$' + (41 * 12).toFixed(1) + '/yr.'/* '$82.5/mo.' */ : paymentSubscription === 'level_5' ? '$' + (83 * 12).toFixed(1) + '/yr.'/* '$165.8/mo.' */ : paymentSubscription === 'level_6' ? '$' + (124 * 12).toFixed(1) + '/yr.' : '')) : /* paymentOption */paymentOptYearlyCal}</StyledTableCell>
                                                        {/* <StyledTableCell style={{ width: '30%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><Button variant="contained" sx={{ color: '#fff' }} onClick={handleCheckout}>Pay Now</Button></StyledTableCell> */}
                                                    </StyledTableRow>
                                                    {/* ))} */}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Box>
                                    :
                                    <Box sx={{ marginTop: '2%', overflowX: 'auto' }}>
                                        <Grid container sx={{ /* marginBottom: '16px',  *//* border: '1px solid #000' */ }}>
                                            <Grid item xs={6}>
                                                {data.map((item, index) => (
                                                    <HeaderCell>{item.heading}</HeaderCell>
                                                ))}

                                            </Grid>
                                            <Grid item xs={6}>
                                                {data.map((item, index) => (
                                                    <DataCell>{item.value}</DataCell>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }

                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                {/*  <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded2 ? '500px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded2 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick2}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Automatically publish newly created blogs
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        Blogs that are created daily will be automatically published and sent to google to be indexed.
                                    </Typography>
                                </Box>
                                <Box>
                                    {expanded2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded2} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', 
                                        }}>
                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={(planDetails ? planDetails?.autopublish : (autoPublish ? autoPublish : false))}
                                                            onChange={handleAutoPublishChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>Automatically publish newly created blog.</span>
                                            </span>
                                            <div style={{ marginLeft: '46px' }}>
                                                <span style={{ fontSize: '16px', fontWeight: 'lighter', display: 'block' }}>This option automatically publish and index your blog when they are created daily. Uncheck box to manually publish your blog page.</span>
                                            </div>
                                        </Grid>




                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid> */}

                {/* <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded3 ? (isMobile ? '800px' : '500px') : isMobile ? '210px' : '100px'
                            }} ref={getcnameEntriesRef}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '210px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded3 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick3}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    White Label Blog URL
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        You will need to add the below DNS information at your domain registrar to white label your blog url.
                                        We will auto verify once the domain is configured.
                                    </Typography>
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        <b>Note:</b> After campaign is created please go to My Campaigns, Edit, to see the DNS setting here.
                                    </Typography>
                                </Box>
                                        <Box>
                                            {expanded3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </Box>

                            </Button>
                            <Collapse in={expanded3 } style={{ marginTop: '2%' }}>
                                <div style={{ overflowX: isMobile ? 'auto' : 'visible' }}>
                                    <span style={{ display: 'flex', alignItems: 'center', marginLeft: '1%' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={planDetails ? planDetails?.owndomain : (useOwnDomain ? useOwnDomain : false)}
                                                    onChange={handleOwnDomainChange}
                                                    style={{ color: '#344054', borderColor: '#344054' }}

                                                />
                                            }
                                            label=""
                                        />
                                        <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold', color: '#191D23' }}>Yes, I want to use my own domain name</span>
                                        <div className="col-md-6" style={{ display: 'flex', alignItems: 'right', marginTop: isMobile ? '3%' : '', justifyContent: 'flex-end' }}>
                                            <Tooltip title="Click here for help video">
                                                <YouTubeIcon sx={{ color: 'red', fontSize: '34px', marginLeft: '5%', background: '#fff', borderRadius: '49%', marginTop: isMobile ? '3%' : '' }} onClick={() => window.open("https://youtube.com/embed/p9tcr92gGHk?autoplay=1&mute=0&rel=0", '_blank')} />
                                            </Tooltip>

                                        </div>
                                    </span>
                                    {isBrowser ?
                                        (isLoadingCnameRec ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={28} /></div> :
                                            <Table sx={{ minWidth: 700, width: '100%', marginTop: '2%' }} aria-label="customized table">
                                                <TableHead style={{ backgroundColor: ' #EAECF0', border: "gray" }}>
                                                    <TableRow>
                                                        <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">DOMAIN NAME</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">RECORD TYPE</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">HOST NAME</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", width: "20%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">REQUIRED VALUE</StyledTableCell>
                                                        <StyledTableCell style={{ border: "gray", width: "10%", color: '#626F86', fontSize: '14px', fontWeight: 'bold' }} align="center">VERIFY</StyledTableCell>
                                                        

                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {cnameRecords && cnameRecords.map((record: any, index: any) => (
                                                        <StyledTableRow>
                                                            <StyledTableCell style={{ width: '20%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{cnameDomainName.mydomain_name}</StyledTableCell>
                                                            <StyledTableCell style={{ width: '10%', fontSize: '16px', color: '#344054', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>CNAME</StyledTableCell>
                                                            <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><TextField variant="outlined" sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#9FADBC',
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#9FADBC',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#9FADBC',
                                                                    },
                                                                    height: 45
                                                                },
                                                            }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                edge="end"
                                                                                size="small"
                                                                                sx={{
                                                                                    marginRight: '4px',
                                                                                    background: '#000',
                                                                                    borderRadius: '3px',
                                                                                    padding: '10%',
                                                                                    width: '120%',
                                                                                    fontSize: '14px',
                                                                                    '&:hover': {
                                                                                        background: '#000', 
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleCopyName(record.name)}
                                                                            >
                                                                                Copy
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                value={record.name}></TextField></StyledTableCell>
                                                            <StyledTableCell style={{ width: '20%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}><TextField variant="outlined" sx={{
                                                                '& .MuiOutlinedInput-root': {
                                                                    '& fieldset': {
                                                                        borderColor: '#9FADBC',
                                                                    },
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#9FADBC',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#9FADBC',
                                                                    },
                                                                    height: 45
                                                                },
                                                            }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                edge="end"
                                                                                size="small"
                                                                                sx={{
                                                                                    marginRight: '4px',
                                                                                    background: '#000',
                                                                                    borderRadius: '3px',
                                                                                    padding: '10%',
                                                                                    width: '120%',
                                                                                    fontSize: '14px',
                                                                                    '&:hover': {
                                                                                        background: '#000', 
                                                                                    },
                                                                                }}
                                                                                onClick={() => handleCopyVal(record.value)}
                                                                            >
                                                                                Copy
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }} value={record.value}>
                                                            </TextField></StyledTableCell>
                                                            <StyledTableCell style={{ width: '15%', textAlign: 'center', borderBottom: '1px solid #D0D5DD' }}>{record.verified === true ? <div style={{ border: '1px solid #027A48', borderRadius: '20px', padding: '5%', textAlign: 'center', background: '#A6F4C5' }}><span style={{ color: '#027A48', fontWeight: 'bold', fontSize: '16px' }}><VerifiedIcon />Verified</span></div> : <div style={{ border: '1px solid #D43131', borderRadius: '20px', padding: '5%', textAlign: 'center' }}><span style={{ color: '#D43131', fontWeight: 'bold', fontSize: '16px' }}>Non-verified</span></div>}</StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>)
                                        :
                                        <Box sx={{ marginTop: '2%', overflowX: 'auto' }}>
                                            {isLoadingCnameRec ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress size={28} /></div> :
                                                <Grid container>
                                                    {cnameRecords && cnameRecords.map((rec: any, index: any) => (
                                                        <>
                                                            <Grid item xs={6}>
                                                                <HeaderCell>DOMAIN NAME</HeaderCell>
                                                                <HeaderCell>RECORD TYPE</HeaderCell>
                                                                <HeaderCell>HOST NAME</HeaderCell>
                                                                <HeaderCell>REQUIRED VALUE</HeaderCell>
                                                                <HeaderCell>VERIFY</HeaderCell>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <DataCell>{cnameDomainName.mydomain_name}</DataCell>
                                                                <DataCell>CNAME</DataCell>
                                                                <DataCell><TextField variant="outlined" sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: '#9FADBC',
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#9FADBC',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#9FADBC',
                                                                        },
                                                                        height: 30,
                                                                        marginBottom: '-6%'
                                                                    },
                                                                }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        marginRight: '8px',
                                                                                        background: '#000',
                                                                                        borderRadius: '3px',
                                                                                        padding: '5%',
                                                                                        width: '140%',
                                                                                        fontSize: '10px',
                                                                                        '&:hover': {
                                                                                            background: '#000',
                                                                                        },
                                                                                    }}
                                                                                    onClick={() => handleCopyName(rec.name)}
                                                                                >
                                                                                    Copy
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} value={rec.name}></TextField></DataCell>
                                                                <DataCell><TextField variant="outlined" sx={{
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: '#9FADBC',
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: '#9FADBC',
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: '#9FADBC',
                                                                        },
                                                                        height: 30,
                                                                        marginBottom: '-6%'
                                                                    },
                                                                }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    edge="end"
                                                                                    size="small"
                                                                                    sx={{
                                                                                        marginRight: '8px',
                                                                                        background: '#000',
                                                                                        borderRadius: '3px',
                                                                                        padding: '5%',
                                                                                        width: '140%',
                                                                                        fontSize: '10px',
                                                                                        '&:hover': {
                                                                                            background: '#000',
                                                                                        },
                                                                                    }}
                                                                                    onClick={() => handleCopyVal(rec.value)}
                                                                                >
                                                                                    Copy
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    value={rec.value}></TextField></DataCell>
                                                                <DataCell>{rec.verified === true ? <div style={{ border: '1px solid #027A48', borderRadius: '20px', textAlign: 'center', background: '#A6F4C5' }}><span style={{ color: '#027A48', fontWeight: 'bold', fontSize: '10px' }}><VerifiedIcon />Verified</span></div> : <div style={{ border: '1px solid #D43131', borderRadius: '20px', textAlign: 'center' }}><span style={{ color: '#D43131', fontWeight: 'bold', fontSize: '10px' }}>Non-verified</span></div>}</DataCell>

                                                                <br />
                                                            </Grid>

                                                        </>
                                                    ))}
                                                </Grid>
                                            }
                                        </Box>
                                    }
                                </div>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid> */}

                {/* <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded4 ? '600px' : (isMobile ? '150px' : '100px')
                            }}
                            ref={getVerifyScript}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded4 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick4}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Verify Page
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        For compliance reasons, we need to verify that you have control of the page that we're getting data from.
                                    </Typography>
                                </Box>
                                <Box>
                                    {expanded4 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded4} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054',
                                        }}>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Page Verify</Typography>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'lighter' }}>To adhere Ai content laws we need to verify that you control the page that we are using as the basis for the blog that we are creating.
                                                Add code snippet in the footer of the page that we get the data from.</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', 
                                        }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>Add code snippet</Typography>
                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                    {verifyCheck === 'Failed' ? <button style={{ border: '1px solid #D43131', borderRadius: '20px', color: '#D43131', fontWeight: 'bold', fontSize: '16px', textAlign: 'center',  display: 'flex', alignItems: 'center' }}><CloseIcon />Not-Verified</button> : verifyCheck === 'Success' ? <button style={{ border: '1px solid #027A48', borderRadius: '20px', color: '#027A48', fontWeight: 'bold', fontSize: '16px', textAlign: 'center', background: '#A6F4C5', display: 'flex', alignItems: 'center' }}><VerifiedIcon />Verified</button> : ''}
                                                    <Button variant="contained" sx={{ color: '#fff', fontWeight: 'bold', backgroundColor: '#000' }} onClick={() => handleCopyscript(code_snippet)}>Copy</Button>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextareaAutosize style={{ borderRadius: '7px', border: '1px solid #CBCBCB', height: '150px', width: '100%', fontSize: '14px', fontWeight: 'lighter' }} value={code_snippet}></TextareaAutosize>
                                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                                <Button variant="contained" sx={{ color: '#fff', width: '10%' }} onClick={handleVerifyCheckClick} disabled={!code_snippet}>Verify</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid> */}

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded10 ? '500px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded10 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick10}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Author
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        Use this information as the Author of the blogs
                                    </Typography>
                                </Box>
                                <Box>
                                    {expanded10 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded10} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <div>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Name</Typography>
                                                <TextField
                                                    placeholder="Enter your name"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                        // marginLeft: '10px'
                                                    }}
                                                    value={planDetails?.name || userDetails?.name || name/* planDetails ? planDetails?.name : name */}
                                                    onChange={handleAuthorNameChange/* (e) => setName(e.target.value) */} />
                                            </div>
                                            <div style={{ marginTop: '1%' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Upload your profile image</Typography>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ width: '100%' }}
                                                    onChange={handleImageChange}
                                                />
                                                {(userImageUrl) && (
                                                    <img src={userImageUrl}/* src={`${userImageUrl}?${Date.now()}`} */ alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                                )}
                                            </div>
                                            <div style={{ marginTop: '1%' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>URL</Typography>
                                                <TextField
                                                    placeholder="E.g. https://www.linkedin.com/in/profile_name"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                        // marginLeft: '10px'
                                                    }}
                                                    value={planDetails ? planDetails?.social_url : socialUrl}
                                                    onChange={handleAuthorUrlChange/* (e) => setSocialUrl(e.target.value) */} />
                                            </div>
                                            <div style={{ marginTop: '1%' }}>
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.use_as_default : useAsDefault}
                                                                onChange={handleAuthorUseDefaultChange/* (e) => setUseAsDefault(e.target.checked) */}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold', color: '#191D23' }}>Use this information on my blogs.</span>
                                                </span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded5 ? '500px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded5 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick5}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Add Tags{/* <span style={{ color: 'red' }}>*</span> */}
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        This will add keyword tags to each page for better SEO search results
                                    </Typography>
                                </Box>
                                <Box>
                                    {expanded5 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded5} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={planDetails ? planDetails?.addtags : (addtags ? addtags : false/* localStorage.getItem('addTags') */)}
                                                            onChange={handleaddtagsChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>{`Yes, please add tags to my pages (example below)`}</span>
                                            </span>
                                            <div style={{ marginLeft: '46px' }}>
                                                <span style={{ fontSize: '16px', fontWeight: 'lighter', display: 'block' }}><span style={{ color: '#D92D20', fontSize: '16px', fontWeight: 'bold' }}>Tags:</span> 2024 giftings, beach, blockchain, email, google, Publish, Update, Author & Tags, dropdown<br />
                                                    new tag, HubSpot account, website etc.</span>
                                            </div>
                                            {/* <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={planDetails ? planDetails?.video : (video ? video : localStorage.getItem('video'))}
                                                            onChange={handlevideoChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>{`Yes, add a video to each of my blog page`}</span>
                                            </span> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded11 ? '500px' : (isMobile ? '265px' : '150px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '265px' : '150px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded11 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick11}
                            >

                                <Box sx={{ textAlign: 'left' }}>
                                    Add Video
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        This will automatically add a video to your page.  This will create engagement and better ranking of your pages<br></br>
                                        Note: We currently support only YouTube videos.  You can add 1 YouTube video url below.  The video will be added to all new blogs created.  You can change the video for each blog from the My Blogs page by clicking Edit Blog.
                                    </Typography>
                                </Box>
                                {/* {(subscriptionTH === true && mode === 'Free Trial') ?
                                    <Box>
                                        <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Pending Activation</Button>
                                    </Box> :
                                    (subscriptionFromCampList || planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y' || planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5' || planDetails?.mode == "Free Trial" || selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1' || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5'
                                        || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
                                        || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5'
                                    ) ?
                                        <Box>
                                            <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Campaign Hacker Premium Feature</Button>
                                        </Box>
                                        : */}
                                <Box>
                                    {expanded11 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>{/* } */}
                            </Button>
                            <Collapse in={expanded11 /* && !subscriptionFromCampList */} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054',
                                        }}>

                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={planDetails ? planDetails?.video : (video ? video : false)}
                                                            onChange={handlevideoChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>{`Yes, add a video to each of my blog page`}</span>
                                            </span>
                                        </Grid>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054',
                                        }}>
                                            <TextareaAutosize style={{ borderRadius: '7px', border: '1px solid #CBCBCB', height: '150px', width: '100%', fontSize: '14px', fontWeight: 'lighter' }} placeholder={placeholderText} value={/* planDetails ? planDetails.videotag : */ videoTag} onChange={handlevideotag}></TextareaAutosize>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded9 ? 'none' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded9 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick9}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Capture Leads{/* <span style={{ color: 'red' }}>*</span> */}
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        This will add a leads capture from on your blog pages.
                                    </Typography>
                                </Box>
                                {/* {(subscriptionTH === true && mode === 'Free Trial') ?
                                    <Box>
                                        <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Pending Activation</Button>
                                    </Box> :
                                    (subscriptionFromCampList || planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y' || planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5' || planDetails?.mode == "Free Trial" || selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1' || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5'
                                        || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
                                        || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5'
                                    ) ?
                                        <Box>
                                            <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Campaign Hacker Premium Feature</Button>
                                        </Box>
                                        : */}
                                <Box>
                                    {expanded9 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>{/* } */}
                            </Button>
                            <Collapse in={expanded9 /* && !subscriptionFromCampList *//* (planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5') */} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={planDetails ? planDetails?.capturleads : (capturleads ? capturleads : true/* localStorage.getItem('captureLeads') */)}
                                                            onChange={handlecapturleadsChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>Yes, please add the below leads capture form to my blogs.</span>
                                            </span>
                                            <div style={{ display: 'flex', justifyContent: 'center'/* , alignItems: 'center' */, height: isMobile ? '70vh' : '70vh' }}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #CBCBCB',
                                                        padding: '3%',
                                                        borderRadius: '6px',
                                                        width: isMobile ? '95%' : '50%',
                                                        height: isMobile ? '450px' : '500px',
                                                        marginTop: '2%'
                                                    }}>
                                                    <div>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder', color: '#191D23' }}>Contact Us</Typography>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'lighter' }}>Reach out to us and let us know how we can help you.</Typography>
                                                    </div>
                                                    <div style={{ marginTop: '7%' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Name</Typography>
                                                        <TextField
                                                            placeholder="Enter your name"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '40px',
                                                                '.MuiInputBase-root': {
                                                                    height: '100%',
                                                                    border: 'none',
                                                                    borderRadius: '5px',
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                                border: '1px solid #CBCBCB',
                                                                borderRadius: '5px',
                                                                '&:hover': {
                                                                    border: 'none'
                                                                }
                                                                // marginLeft: '10px'
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }} />
                                                    </div>
                                                    <div style={{ marginTop: '2%' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Email</Typography>
                                                        <TextField
                                                            placeholder="Enter your email"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '40px',
                                                                '.MuiInputBase-root': {
                                                                    height: '100%',
                                                                    border: 'none',
                                                                    borderRadius: '5px',
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                                border: '1px solid #CBCBCB',
                                                                borderRadius: '5px',
                                                                '&:hover': {
                                                                    border: 'none'
                                                                }
                                                                // marginLeft: '10px'
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }} />
                                                    </div>
                                                    <div style={{ marginTop: '2%' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Phone</Typography>
                                                        <TextField
                                                            placeholder="Enter your phone number"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '40px',
                                                                '.MuiInputBase-root': {
                                                                    height: '100%',
                                                                    border: 'none',
                                                                    borderRadius: '5px',
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                                border: '1px solid #CBCBCB',
                                                                borderRadius: '5px',
                                                                '&:hover': {
                                                                    border: 'none'
                                                                }
                                                                // marginLeft: '10px'
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }} />
                                                    </div>
                                                    <div style={{ marginTop: '2%' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>How can we help?</Typography>
                                                        <TextField
                                                            placeholder="Let us know how we can help you"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '40px',
                                                                '.MuiInputBase-root': {
                                                                    height: '100%',
                                                                    border: 'none',
                                                                    borderRadius: '5px',
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                                border: '1px solid #CBCBCB',
                                                                borderRadius: '5px',
                                                                '&:hover': {
                                                                    border: 'none'
                                                                }
                                                                // marginLeft: '10px'
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }} />
                                                    </div>
                                                    <div style={{ marginTop: '5%' }}>
                                                        {/* <Button variant="contained" sx={{width: '100%'}}>Subscribe</Button> */}
                                                        <Button variant="contained" sx={{
                                                            width: '100%', background: '#EAECF0', color: '#667085', '&:hover': {
                                                                background: '#EAECF0',
                                                            }
                                                        }}>Contact Us</Button>
                                                    </div>

                                                </Box>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                {/* <TwitterIcon /> */}&nbsp;
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded6 ? '500px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded6 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick6}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    My Social Media Links{/* <span style={{ color: 'red' }}>*</span> */}
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        This will drive traffic from your blog to your social media page with clicked on.
                                    </Typography>
                                </Box>
                                {/* {(subscriptionTH === true && mode === 'Free Trial') ?
                                    <Box>
                                        <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Pending Activation</Button>
                                    </Box> :
                                    (subscriptionFromCampList || planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y' || planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5'
                                        || planDetails?.mode == "Free Trial" || selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
                                        || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5'
                                        || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
                                        || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') ?
                                        <Box>
                                            <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Campaign Hacker Premium Feature</Button>
                                        </Box>
                                        : */}
                                <Box>
                                    {expanded6 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>{/* } */}
                            </Button>
                            <Collapse in={expanded6 /* && !subscriptionFromCampList *//* (planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5') */} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={!FromEdit ? (planDetails ? planDetails?.linkedinurl || planDetails?.fburl || planDetails?.twitterurl || planDetails?.pintresturl : (socialMediaUrlPublish != null ? socialMediaUrlPublish : localStorage.getItem('smUrlpublish'))) : socialMediaUrlPublish != null ? socialMediaUrlPublish : planDetails ? planDetails?.linkedinurl || planDetails?.fburl || planDetails?.twitterurl || planDetails?.pintresturl : 0}
                                                            onChange={handlesocialMediaUrlPublishChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>Yes, please add the below social media links to my blog pages.</span>
                                            </span><br></br><br></br>
                                            <div style={{ marginLeft: '46px', display: 'flex' }}>
                                                <TwitterIcon /><TextField
                                                    placeholder="Paste your tweeter URL"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '50%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            '.MuiOutlinedInput-root': {
                                                                height: '100%',
                                                                border: 'none',
                                                                borderRadius: '5px',
                                                                '&:hover fieldset': {
                                                                    borderColor: '#CBCBCB',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: '#CBCBCB',
                                                                    boxShadow: 'none',
                                                                },
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        marginLeft: '10px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                    }}
                                                    value={planDetails ? planDetails?.twitterurl : twitterUrl}
                                                    onChange={handletwitterUrlChange}></TextField>
                                            </div>

                                            <div style={{ marginLeft: '46px', display: 'flex', marginTop: '2%' }}>
                                                <PinterestIcon /><TextField
                                                    placeholder="Paste your pinterest URL"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '50%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        marginLeft: '10px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                    }}
                                                    value={planDetails ? planDetails?.pintresturl : pintrestUrl}
                                                    onChange={handlepintrestUrlChange}></TextField>
                                            </div>

                                            <div style={{ marginLeft: '46px', display: 'flex', marginTop: '2%' }}>
                                                <FacebookIcon /><TextField
                                                    placeholder="Paste your facebook URL"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '50%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        marginLeft: '10px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                    }}
                                                    value={planDetails ? planDetails?.fburl : fbUrl}
                                                    onChange={handlefbUrlChange}></TextField>
                                            </div>

                                            <div style={{ marginLeft: '46px', display: 'flex', marginTop: '2%' }}>
                                                <LinkedinIcon /><TextField
                                                    placeholder="Paste your linkedIn URL"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '50%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        marginLeft: '10px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                    }}
                                                    value={planDetails ? planDetails?.linkedinurl : linkedinUrl}
                                                    onChange={handlelinkedinUrlChange}></TextField>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded7 ? '700px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded7 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick7}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Newsletter{/* <span style={{ color: 'red' }}>*</span> */}
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        Your audience will be able to sign up for your newsletter, allowing you to capture leads and follow up.
                                    </Typography>
                                </Box>
                                {/*  {(subscriptionTH === true && mode === 'Free Trial') ?
                                    <Box>
                                        <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Pending Activation</Button>
                                    </Box> :
                                    (subscriptionFromCampList || planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y'
                                        || planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2'
                                        || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5' || planDetails?.mode == "Free Trial"
                                        || selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
                                        || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5'
                                        || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
                                        || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') ?
                                        <Box>
                                            <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Campaign Hacker Premium Feature</Button>
                                        </Box>
                                        : */}
                                <Box>
                                    {expanded7 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>{/* } */}
                            </Button>
                            <Collapse in={expanded7 /* && !subscriptionFromCampList *//* (planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5') */} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={planDetails ? planDetails?.newsletter : (newsletter ? newsletter : true/* localStorage.getItem('newsLetter') */)}
                                                            onChange={handlenewsletterChange}
                                                            style={{ color: '#344054', borderColor: '#344054' }}

                                                        />
                                                    }
                                                    label=""
                                                />
                                                <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}>Yes, please add the newsletter form to my blog pages.</span>
                                            </span>
                                            <div style={{ display: 'flex', justifyContent: 'center'/* , alignItems: 'center' */, height: isMobile ? '40vh' : '50vh' }}>
                                                <Box
                                                    sx={{
                                                        border: '1px solid #CBCBCB',
                                                        padding: '3%',
                                                        borderRadius: '6px',
                                                        width: isMobile ? '95%' : '50%',
                                                        height: isMobile ? '250px' : '300px',
                                                        marginTop: '2%'
                                                    }}>
                                                    <div>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder', color: '#191D23' }}>Newsletter</Typography>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'lighter' }}>Signup for exclusive offers, original stories, events and more.</Typography>
                                                    </div>
                                                    <div style={{ marginTop: '7%' }}>
                                                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Email</Typography>
                                                        <TextField className=""
                                                            placeholder="Enter your email"
                                                            sx={{
                                                                backgroundColor: '#fff',
                                                                width: '100%',
                                                                height: '40px',
                                                                '.MuiOutlinedInput-root': {
                                                                    height: '100%',
                                                                    border: 'none',
                                                                    borderRadius: '5px',
                                                                    '&:hover fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                    },
                                                                    '&.Mui-focused fieldset': {
                                                                        borderColor: '#CBCBCB',
                                                                        boxShadow: 'none',
                                                                    },
                                                                },
                                                                border: '1px solid #CBCBCB',
                                                                borderRadius: '5px',
                                                                '&:hover': {
                                                                    border: 'none'
                                                                }
                                                                // marginLeft: '10px'
                                                            }}
                                                            inputProps={{
                                                                readOnly: true,
                                                            }} />
                                                    </div>
                                                    <div style={{ marginTop: '7%' }}>
                                                        {/* <Button variant="contained" sx={{width: '100%'}}>Subscribe</Button> */}
                                                        <Button variant="contained" sx={{
                                                            width: '100%', background: '#EAECF0', color: '#667085', '&:hover': {
                                                                background: '#EAECF0',
                                                            }
                                                        }}>Subscribe</Button>
                                                    </div>

                                                </Box>
                                            </div>
                                            {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                    <TwitterIcon />
                                                </div> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded8 ? '600px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded8 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick8}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Social Media Share Buttons{/* <span style={{ color: 'red' }}>*</span> */}
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        This will allow your audienceto share you blog page on social media.
                                    </Typography>
                                </Box>
                                {/* {(subscriptionTH === true && mode === 'Free Trial') ?
                                    <Box>
                                        <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Pending Activation</Button>
                                    </Box> :
                                    (subscriptionFromCampList || planDetails?.subscription_level === 'level_0' || planDetails?.subscription_level === 'level_0y'
                                        || planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2'
                                        || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5' || planDetails?.mode == "Free Trial"
                                        || selectedSubscriptionLevel == 'level_0' || selectedSubscriptionLevel == 'level_0y' || selectedSubscriptionLevel == 'level_1'
                                        || selectedSubscriptionLevel == 'level_2' || selectedSubscriptionLevel == 'level_4' || selectedSubscriptionLevel == 'level_5'
                                        || paymentSubscription == 'level_0' || paymentSubscription == 'level_0y' || paymentSubscription == 'level_1'
                                        || paymentSubscription == 'level_2' || paymentSubscription == 'level_4' || paymentSubscription == 'level_5') ?
                                        <Box>
                                            <Button variant="contained" sx={{ fontWeight: 'bold', fontSize: '16px' }}>Campaign Hacker Premium Feature</Button>
                                        </Box>
                                        : */}
                                <Box>
                                    {expanded8 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>{/* } */}
                            </Button>
                            <Collapse in={expanded8 /* && !subscriptionFromCampList *//* (planDetails?.subscription_level === 'level_1' || planDetails?.subscription_level === 'level_2' || planDetails?.subscription_level === 'level_4' || planDetails?.subscription_level === 'level_5') */} style={{ marginTop: '2%' }}>
                                {isBrowser ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <div style={{ display: 'flex', gap: isMobile ? '30px' : '55px', padding: '3%', justifyContent: 'center' }}>
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.twitternbutton : (twitternbutton ? twitternbutton : true/* localStorage.getItem('twitterBtn') */)}
                                                                onChange={handletwitterBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Twitterico /* onClick={shareOnTwitter} style={{cursor: 'pointer'}} */ />
                                                    </span>
                                                </span>

                                                {/* <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.pintrestbutton : (pintrestbutton ? pintrestbutton : false)}
                                                                onChange={handlepintrestBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Pinterestico  />
                                                    </span>
                                                </span> */}
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.fbbutton : (fbbutton ? fbbutton : true/* localStorage.getItem('fbBtn') */)}
                                                                onChange={handlefbBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Fbico /* onClick={shareOnFacebook} style={{cursor: 'pointer'}} */ />
                                                    </span>
                                                </span>
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.linkedinbutton : (linkedinbutton ? linkedinbutton : true/* localStorage.getItem('linkedinBtn') */)}
                                                                onChange={handlelinkedinBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Linkedinico /* onClick={shareOnLinkedin} style={{cursor: 'pointer'}} */ />
                                                    </span>
                                                </span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <div style={{ display: 'flex', gap: isMobile ? '30px' : '55px', padding: '3%', justifyContent: 'center' }}>
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.twitternbutton : (twitternbutton ? twitternbutton : false/* localStorage.getItem('twitterBtn') */)}
                                                                onChange={handletwitterBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Twitterico />
                                                    </span>
                                                </span>

                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.pintrestbutton : (pintrestbutton ? pintrestbutton : false/* localStorage.getItem('pintrestBtn') */)}
                                                                onChange={handlepintrestBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Pinterestico />
                                                    </span>
                                                </span>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <div style={{ display: 'flex', gap: isMobile ? '30px' : '55px', padding: '3%', justifyContent: 'center' }}>

                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.fbbutton : (fbbutton ? fbbutton : false/* localStorage.getItem('fbBtn') */)}
                                                                onChange={handlefbBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Fbico />
                                                    </span>
                                                </span>
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.linkedinbutton : (linkedinbutton ? linkedinbutton : false/* localStorage.getItem('linkedinBtn') */)}
                                                                onChange={handlelinkedinBtnChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold' }}><Linkedinico />
                                                    </span>
                                                </span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                }
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded12 ? '500px' : (isMobile ? '150px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '150px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded12 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={handleExpandClick12}
                            >
                                <Box sx={{ textAlign: 'left' }}>
                                    Call To Action (CTA) Button{/* <span style={{ color: 'red' }}>*</span> */}
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        We will use this as your default CTA button in your blog. You can change this for each blog with the Edit feature
                                    </Typography>
                                </Box>
                                <Box>
                                    {expanded12 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded12} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054', /* display: 'flex', */
                                            // alignItems: 'center'

                                        }}>
                                            <div>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Button Label</Typography>
                                                <TextField
                                                    // placeholder="Enter your name"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                        // marginLeft: '10px'
                                                    }}
                                                    value={planDetails ? planDetails?.cta_lable : ctaLabel || 'Learn More'}
                                                    onChange={handleCtaLabelChange} />
                                            </div>
                                            {/* <div style={{ marginTop: '1%' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Upload your profile image</Typography>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ width: '100%' }}
                                                    onChange={handleImageChange}
                                                />
                                                {userImageUrl && (
                                                    <img src={`${userImageUrl}?${Date.now()}`} alt="Uploaded" style={{ maxWidth: '50px', marginTop: '1rem', height: '50px' }} />
                                                )}
                                            </div> */}
                                            <div style={{ marginTop: '1%' }}>
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#191D23' }}>Button Link</Typography>
                                                <TextField
                                                    // placeholder="E.g. https://www.linkedin.com/in/profile_name"
                                                    sx={{
                                                        backgroundColor: '#fff',
                                                        width: '100%',
                                                        height: '40px',
                                                        '.MuiInputBase-root': {
                                                            height: '100%',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            '&:hover fieldset': {
                                                                borderColor: '#CBCBCB',
                                                            },
                                                            '&.Mui-focused fieldset': {
                                                                borderColor: '#CBCBCB',
                                                                boxShadow: 'none',
                                                            },
                                                        },
                                                        border: '1px solid #CBCBCB',
                                                        borderRadius: '5px',
                                                        '&:hover': {
                                                            border: 'none'
                                                        }
                                                        // marginLeft: '10px'
                                                    }}
                                                    value={planDetails ? planDetails?.cta_link : ctaLink || sourceUrl}
                                                    onChange={handleCtaLinkChange} />
                                            </div>
                                            {/* <div style={{ marginTop: '1%' }}>
                                                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={planDetails ? planDetails?.use_as_default : useAsDefault}
                                                                onChange={handleAuthorUseDefaultChange}
                                                                style={{ color: '#344054', borderColor: '#344054' }}

                                                            />
                                                        }
                                                        label=""
                                                    />
                                                    <span style={{ fontSize: '16px', display: 'block', fontWeight: 'bold', color: '#191D23' }}>Use this information on my blogs.</span>
                                                </span>
                                            </div> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid>

                {/* <Grid container spacing={2} sx={{ marginTop: '3%' }}>
                    <Grid item xs={12} sm={12}>
                        <Box
                            sx={{
                                width: '100%',
                                background: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                overflow: 'hidden',
                                transition: 'max-height 0.3s ease-in-out',
                                maxHeight: expanded13 ? '500px' : (isMobile ? '160px' : '100px')
                            }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100%',
                                    height: isMobile ? '160px' : '100px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    borderBottom: expanded13 ? '1px solid #ddd' : 'none',
                                    background: '#D0D5DD',
                                    justifyContent: 'space-between',
                                    color: '#344054',
                                    '&:hover': {
                                        background: '#D0D5DD',
                                    }
                                }}
                                onClick={() => setExpanded13(!expanded13)}
                            >

                                <Box sx={{ textAlign: 'left' }}>
                                    Add Blogs to My Website
                                    <Typography component="span" sx={{ display: 'block', fontSize: '16px', fontWeight: 'normal' }}>
                                        Use the below code to add the blogs we generate to your website.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Tooltip title="Click here for help video">
                                        <img src={youtubeIco1} style={{ fontSize: '34px' }} onClick={() => window.open("https://images.code-genie.ai/images/trafficmonster/iframe_video.mp4", '_blank')} />
                                    </Tooltip>
                                    {expanded13 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Box>
                            </Button>
                            <Collapse in={expanded13} style={{ marginTop: '2%' }}>
                                <Box
                                    sx={{
                                        padding: '16px',
                                        background: '#f9f9f9',
                                        borderRadius: '0 0 8px 8px',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} sx={{
                                            color: '#344054',
                                        }}>
                                            <TextField variant="outlined" sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderColor: '#9FADBC',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#9FADBC',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#9FADBC',
                                                    },
                                                    height: 45
                                                },
                                                width: '100%'
                                            }}
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                edge="end"
                                                                size="small"
                                                                sx={{
                                                                    marginRight: '4px',
                                                                    background: '#000',
                                                                    borderRadius: '3px',
                                                                    padding: '10%',
                                                                    width: '120%',
                                                                    fontSize: '14px',
                                                                    '&:hover': {
                                                                        background: '#000',
                                                                    },
                                                                }}
                                                                onClick={() => handleCopyCodeSnippet(addtoMyblogscodeSnippet)}
                                                            >
                                                                Copy
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={addtoMyblogscodeSnippet} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                    </Grid>
                </Grid> */}

                <div style={{ marginTop: '1%', display: 'flex', justifyContent: 'right' }}>
                    <Button variant="outlined" onClick={() => {
                        navigate('/codegenie/campaign-list');
                        localStorage.removeItem('campaignid');
                        localStorage.removeItem('returningtocampaign');
                        localStorage.removeItem('sourceurl');
                        localStorage.removeItem('campaignName');
                        localStorage.removeItem('paymentOption');
                        localStorage.removeItem('autoPublish');
                        localStorage.removeItem('limitPublish');
                        localStorage.removeItem('smUrlpublish');
                        localStorage.removeItem('addTags');
                        localStorage.removeItem('captureLeads');
                        localStorage.removeItem('newsLetter');
                        localStorage.removeItem('twitterBtn');
                        localStorage.removeItem('fbBtn');
                        localStorage.removeItem('pintrestBtn');
                        localStorage.removeItem('linkedinBtn');
                        localStorage.removeItem('video');
                        localStorage.removeItem('twitterurl');
                        localStorage.removeItem('fburl');
                        localStorage.removeItem('pintresturl');
                        localStorage.removeItem('linkedinurl');
                        localStorage.removeItem('smUrlpublish');
                        localStorage.removeItem('owndomain');
                        localStorage.removeItem('ChangepayMode');
                        localStorage.removeItem('IdChangingMode');
                        localStorage.removeItem('PaymentSubscription');
                        localStorage.removeItem('Authorurl');
                        localStorage.removeItem('Authorname');
                        localStorage.removeItem('authorDefaultCheck');
                        localStorage.removeItem('videoTag');
                        localStorage.removeItem('podcastUrl');
                        localStorage.removeItem('sourcetext');
                    }}>Close</Button>&ensp;<Button variant="contained" onClick={() => {
                        if (planDetails?.payment_status === 'pending') {
                            handleCheckout(planDetails?._id);
                        } else {
                            handleCreateCampaignClick();
                        }
                    }} style={{ filter: isUploadLoading ? 'blur(2px)' : 'none', pointerEvents: isUploadLoading ? 'none' : 'auto' }} /* disabled={((defaultPlanDetails?.mode == "Free Trial" && FromEdit != "startEditing") || (localStorage.getItem('returningtocampaign') === 'true' && FromEdit != "startEditing") || subscriptionFromCampList || ((planDetails?.subscription_level == 'level_3' || planDetails?.subscription_level == 'level_6') && planDetails?.mode == "Free Trial" && FromEdit != 'startEditing'))} */>{isUpdateLoader ? <CircularProgress size={24} style={{ color: '#fff' }} /> : planDetails?.payment_status === 'pending' ? 'Complete Payment' : FromEdit === 'startEditing' || localStorage.getItem('ChangepayMode') === 'true' ? 'Update Campaign' : 'Create Campaign'}</Button>

                </div>
            </div >


            <Modal
                open={openpaymentInstructModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 600,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    {/* <Typography sx={{ fontSize: '25px' }}>ThankYou!</Typography> */}
                    <PriorityHighIcon style={{ color: 'yellow', fontSize: '38px' }} />
                    <p id="child-modal-description" style={{ textAlign: 'center'/* , fontSize: 'xx-large', marginTop: '22px' */ }}>Please make a payment to complete your process.</p>
                    <Stack flexDirection="row">
                        <Button
                            onClick={handleModalPayNowClick}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Pay Now
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={openGetPagesuccessModal}
                onClose={() => setOpenGetPageSuccessModal(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 600,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <TaskAltIcon style={{ color: 'green', fontSize: '40px' }} />
                    <p id="child-modal-description" style={{ textAlign: 'center' }}>We have generated your first blogs. We will automatically generate new blogs daily per your selected plan.  You can edit each blog from the My Blog page with the Edit button and Export the blogs to social media or blogging platforms with the Export button.  Use the Help button on each page to learn more.</p>
                    <Stack flexDirection="row">
                        {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
            </Box> */}

                        <Button
                            onClick={handleGetPageModalClose}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            Close
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal
                open={confirmCancelSubscription}
                onClose={() => setConfirmCancelSubscription(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        transform: "translate(-50%, -50%)",
                        width: isMobile ? "95%" : 800,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        pt: 2,
                        px: 4,
                        pb: 3,
                    }}
                >
                    <InfoIcon style={{ color: 'Orange', fontSize: '40px' }} />
                    <p id="child-modal-description" style={{ textAlign: 'center', color: '#fff' }}>When you cancel your subscription you are also canceling the hosting of your blog pages.  If you would like to keep this content, please copy the content to use on a different platform.  Once cancelled the content will be archived for 30 days then it will be deleted from the Traffic Monster servers and from Google Indexing.</p>
                    <Stack flexDirection="row" sx={{ gap: '10px', marginTop: '2%' }}>
                        {/* <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <Button variant="contained" onClick={() => navigate('/pricing')}>Go For Pricing</Button>
            </Box> */}
                        <Button
                            onClick={handleCancelSubscriptionClick}
                            variant="contained"
                            sx={{ /* mr: 2, */ fontWeight: 'bolder', fontSize: '17px' }}
                        >
                            {cancellingLoader ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Continue'}
                        </Button>
                        <Button
                            onClick={() => setConfirmCancelSubscription(false)}
                            variant="contained"
                            sx={{ /* mr: 2, */ fontWeight: 'bolder', fontSize: '17px' }}
                        >
                            Exit
                        </Button>
                    </Stack>
                </Box>
            </Modal>

        </>
    )
}

export default New_Campaign;